import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CaretDown from 'app/components/icons/side-bar-icons/caret-down';
import './all-contribution-list-modal.scss';
import { getInitials, truncateString } from 'app/utils/common-utils/common-utils';
import { useNavigate } from 'react-router-dom';

function AllContributionsListModal({ open, handleClose, activity }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const [expandedAccordion, setExpandedAccordion] = useState(activity?.resources?.length > 0 ? 0 : null);
  const navigate = useNavigate();
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: '15px',
          width: { xs: '100%', md: '75%', lg: '75%' },
          background: isDarkMode ? '#2C2C38' : '#FFFFFF',
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: 'relative',
          marginBottom: '10px',
          border: 'none',
          maxHeight: '95vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
          '&::-webkit-scrollbar-track': {
            background: isDarkMode ? '#2C2C38' : '#FFFFFF',
          },
        }}
      >
        <Grid item container justifyContent={'flex-end'}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 1,
              color: isDarkMode ? '#ffff' : 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogTitle sx={{ background: isDarkMode ? '#2C2C38' : '#FFFFFF' }}> All contributors</DialogTitle>

        <DialogContent sx={{ padding: '0px' }}>
          {activity?.resources?.map((resource, index) => {
            const participants = activity?.participants.filter(participant => participant.resources.some(r => r.id === resource.id));
            return (
              <Accordion
                key={resource.id}
                expanded={expandedAccordion === index}
                onChange={() => setExpandedAccordion(expandedAccordion === index ? null : index)}
                sx={{
                  width: '100%',
                  background: expandedAccordion === index ? (isDarkMode ? '#1F1F26' : '#F6F7F8') : isDarkMode ? '#2C2C38' : '#FFFFFF',
                }}
              >
                <AccordionSummary
                  expandIcon={<CaretDown sx={{ height: 'unset', width: 'unset' }} />}
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                >
                  <Stack direction={'row'} alignItems={'center'} className="row" width="100%">
                    <Box className="col-8">
                      <Typography
                        sx={{
                          color: theme.palette.mode === 'dark' ? '#ffff' : '#676e7b',
                          fontSize: theme.p1.semiBold.fontSize,
                          fontWeight: theme.p1.semiBold.fontWeight,
                        }}
                      >
                        {index + 1}.{'   '}
                        {resource.resources}
                      </Typography>
                    </Box>
                    <Box className="col-4">
                      <Typography
                        sx={{
                          color: theme.palette.mode === 'dark' ? '#ffff' : '#676e7b',
                          fontSize: theme.p1.semiBold.fontSize,
                          fontWeight: theme.p1.medium.fontWeight,
                        }}
                      >
                        {resource?.filled}/{resource?.totalCount} filled
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ background: isDarkMode ? '#2C2C38' : '#FFFFFF' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                      borderRadius: '16px',
                      p: 2,
                      width: '100%',
                      overflowX: 'hidden',
                      boxShadow: 'none',
                      overflowY: participants?.length > 2 ? 'scroll' : 'hidden',
                      scrollbarWidth: 'thin',
                      '&::-webkit-scrollbar': {
                        width: '3px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'secondary.contrastText',
                        borderRadius: '16px',
                      },
                    }}
                  >
                    <Stack direction="column" spacing={1} gap={3} paddingLeft={3} paddingRight={3}>
                      {participants.length > 0 ? (
                        participants
                        .filter(participant => 
                          participant.resources.some(contribution => contribution.id === resource.id && contribution.filled > 0)
                        )
                        .map(participant => (
                          <Grid
                            container
                            key={participant.id}
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            justifyContent={'space-between'}
                          >
                            <Stack direction="row" gap={1} alignItems="center">
                              <Avatar
                                alt={participant.profilePic ? '' : getInitials(participant.name)}
                                src={participant.profilePic ? participant.profilePic : ''}
                                sx={{
                                  width: '30px',
                                  height: '29px',
                                  cursor: 'pointer',
                                  fontSize: participant.profilePic ? 'default' : '12px',
                                }}
                                onClick={() => {
                                  const encodedTitle = participant.name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
                                  const path = participant.organizationId
                                    ? `/organization/${encodedTitle}-${participant.organizationId}`
                                    : `/profile/${encodedTitle}-${participant.id}`;

                                  navigate(path);
                                }}
                              >
                                {' '}
                                {getInitials(participant.name)} 
                              </Avatar>
                              <Typography title={participant.name} fontWeight={'600'} sx={{ color: isDarkMode ? '#ffff' : '#292D34' }}>
                                {truncateString(participant.name, 40)}
                              </Typography>
                            </Stack>
                            <Stack>
                              {participant.resources.map(contribution => (
                                <div key={contribution.id}>
                                  {contribution.id === resource.id && (
                                    <Typography sx={{ color: isDarkMode ? '#ffff' : '#888A8E' }}>
                                      {contribution.filled} 
                                    </Typography>
                                  )}
                                </div>
                              ))}
                            </Stack>
                          </Grid>
                        ))
                      ) : (
                        <Typography>No contributions yet</Typography>
                      )}
                    </Stack>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default AllContributionsListModal;
