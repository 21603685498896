

function metaTagUpdateReverse() {
    let metaDescription = document.querySelector('meta[name="description"]');
    let metaOgTitle = document.querySelector('meta[property="og:title"]');
    let metaOgDescription = document.querySelector('meta[property="og:description"]');
    let pageTitle = document.querySelector('title');
    let metaImage = document.querySelector('meta[property="og:image"]');
    let twitterDescription = document.querySelector('meta[name="twitter:description"]');
    let twitterTitle = document.querySelector('meta[name="twitter:title"]');
    let twitterImage = document.querySelector('meta[name="twitter:image"]');
    let twitterCard = document.querySelector('meta[name="twitter:card"]')
    if (twitterCard) {
        twitterCard.parentNode.removeChild(twitterCard);

    }
    if (twitterDescription) {
        twitterDescription.parentNode.removeChild(twitterDescription);
    }
    if (twitterTitle) {
        twitterTitle.parentNode.removeChild(twitterTitle);
    }
    if (twitterImage) {
        twitterImage.parentNode.removeChild(twitterImage);
    }
    if (metaDescription) {
        metaDescription.setAttribute("content", "ReachAMY description");
    }

    if (pageTitle) {
        pageTitle.textContent = "ReachAmy";
    }

    if (metaImage) {
        metaImage.setAttribute("content", "https://qa.reachamy.com/favicon.ico");
    }
    if (metaOgDescription) {
        metaOgDescription.setAttribute("content", "ReachAMY description");
    }
    if (metaOgTitle) {
        metaOgTitle.setAttribute("content", "ReachAMY title");
    }
}


export default metaTagUpdateReverse;
