import { Fab, useTheme, Box } from '@mui/material';
import React from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useLocation } from 'react-router-dom';

const SocialIcons = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const location = useLocation();

  const fabStyles = {
    width: 30,
    height: 30,
    minHeight: 30, 
    fontSize: '0.8rem',
    fontWeight: 'bold',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.6)',
    color: (location.pathname === '/' || location.pathname === '/blog-list') ? '#ffffff' : (isDarkMode ? '#000000' : '#ffffff'),
    backgroundColor: (location.pathname === '/' || location.pathname === '/blog-list') ? '#674bff' : (isDarkMode ? '#FFCE00' : '#674bff'),
  '&:hover': {
    backgroundColor: (location.pathname === '/' || location.pathname === '/blog-list') ? '#5638d4' : (isDarkMode ? '#e0b700' : '#5638d4'),
  },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        gap: 1.5, 
        py: 2, 
      }}
    >
      <Fab sx={fabStyles} aria-label="youtube" href="https://www.youtube.com/channel/UCQroVsuknPeco2Y3b3UqCzQ" target="_blank"
        rel="noopener noreferrer">
        <YouTubeIcon sx={{ fontSize: 18 }} /> 
      </Fab>
      <Fab sx={fabStyles} aria-label="instagram" href="https://instagram.com/reachamy.official/" target="_blank"
        rel="noopener noreferrer">
        <InstagramIcon sx={{ fontSize: 18 }} /> 
      </Fab>
      <Fab sx={fabStyles} aria-label="X" href="https://x.com/reach_amy"  target="_blank"
        rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18px"
          height="18px" 
        >
          <path
            d="M 2.3671875 3 L 9.4628906 13.140625 L 2.7402344 21 L 5.3808594 21 L 10.644531 14.830078 L 14.960938 21 L 21.871094 21 L 14.449219 10.375 L 20.740234 3 L 18.140625 3 L 13.271484 8.6875 L 9.2988281 3 L 2.3671875 3 z M 6.2070312 5 L 8.2558594 5 L 18.033203 19 L 16.001953 19 L 6.2070312 5 z"
            fill={isDarkMode ? '#000000' : '#FFFFFF'} 
          />
        </svg>
      </Fab>
      <Fab sx={fabStyles} aria-label="facebook" href="https://www.facebook.com/profile.php?id=61572024388261" target="_blank"
        rel="noopener noreferrer">
        <FacebookRoundedIcon sx={{ fontSize: 18 }} /> 
      </Fab>
      <Fab sx={fabStyles} aria-label="linkedin" href="https://www.linkedin.com/company/105835398/admin/dashboard/" target="_blank"
        rel="noopener noreferrer">
        <LinkedInIcon sx={{ fontSize: 18 }} /> 
      </Fab>
    </Box>
  );
};

export default SocialIcons;
