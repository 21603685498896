import React, { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { bloodType, fetchCategories, subCategoryType } from 'app/shared/reducers/categories';
import Stack from '@mui/material/Stack';
import { Autocomplete, Box, OutlinedInput, TextField, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { sideBaricons } from 'app/utils/data/side-bar-nav/side-nav-icon-list';
import CurrencyInput from 'react-currency-input-field';
// import currencyCode from 'app/utils/data/json/country-currency-code.json';
import currencyCode from 'app/utils/data/json/country-currency-code.json';

interface CreateActivitySelectCategoryProps {
  setCreateActivityData: any;
  formik: any;
}
interface CountryCode {
  name: string;
  symbol: string;
  countryCode: string;
  code: string;
  flag: string;
  symbol_native: string;
}

const CreateActivitySelectCategory = ({ setCreateActivityData, formik }: CreateActivitySelectCategoryProps) => {
  const [categories, setCategories] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [bloodTypes, setBloodTypes] = useState<any>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const [selectedSubCategory, setSelectedSelectedSubCategory] = useState<string>('');
  const [selectedBloodType, setSelectedBloodType] = useState<any>('');
  const [selectedTypeOfActivity, setSelectedTypeOfActitvity] = useState<any>([]);
  const [selectedActivityId, setSelectedActivityId] = useState<string>('');
  const [selectedGoal, setSelectedGoal] = useState<any>(null);
  const isDarkMode = useAppSelector(state => state.darkMode);
  const theme = useTheme();
  const countryCodes = currencyCode as CountryCode[];
  const handleSelectCategory = (event: SelectChangeEvent) => {
    formik.setFieldValue('categoryID', event.target.value);
    formik.setFieldValue('subCategoryID', null);
    formik.setFieldValue('lostPet.subCategoryID', '');
    formik.setFieldValue('activityTypeId', '');
    formik.setFieldValue('goalId', '');
    formik.setFieldValue('quantity', '');
    setSelectedSelectedSubCategory('');
    setSelectedActivityId('');
    setSelectedGoal('');
    setSelectedCategoryId(event.target.value);
  };
  useEffect(() => {
    if (formik.values.categoryID === 19) {
      formik.setFieldValue('urgent', true);
      formik.setFieldValue('urgentValue', true);
      formik.setFieldValue('attendees', false);
      formik.setFieldValue('participantOption', "VOLUNTEER_ONLY");
      formik.setFieldValue('attendeeCapacity', '');
      formik.setFieldValue('volunteer', true);
    } else {
      formik.setFieldValue('urgent', false);
      formik.setFieldValue('urgentValue', false);
    }
  }, [formik.values.categoryID]);

  const handleSelectSubCategory = (event: SelectChangeEvent) => {
    const subCategoryValue = event.target.value;
    setSelectedSelectedSubCategory(subCategoryValue);
    formik.setFieldValue('subCategoryID', subCategoryValue);
    formik.setFieldValue('lostPet.subCategoryID', subCategoryValue);
    formik.setFieldValue('activityTypeId', '');
    formik.setFieldValue('goalId', '');
    formik.setFieldValue('quantity', '');
    setSelectedGoal('');
    if (formik.values?.categoryID === 19 && subCategoryValue !== '14') {
      console.log('changes in subcategory');
      fetchGoalUrgentData();
    }
  };
  const handleSelectBloodType = (event: SelectChangeEvent) => {
    const BloodType = event.target.value;
    setSelectedBloodType(BloodType);
    formik.setFieldValue('bloodTypeId', BloodType);
  };
  const handleActivityType = (event: SelectChangeEvent) => {
    setSelectedActivityId(event.target.value);
    formik.setFieldValue('activityTypeId', event.target.value);
  };

  const fetchActivityData = async (id: string) => {
    if (selectedSubCategory) {
      try {
        const response = await axios.get<subCategoryType[]>(`/api/activity-types/${id}/${selectedCategoryId}`);
        setSelectedTypeOfActitvity(response?.data);
      } catch (error) {
        console.log('Error fetching activities:', error);
      }
    }
  };
  const fetchGoalData = async (id: string) => {
    if (selectedActivityId) {
      try {
        const response = await axios.get<subCategoryType>(`/api/goals/${id}/${selectedSubCategory}/${selectedCategoryId}`);
        if (response?.data) {
          setSelectedGoal(response.data);
          formik.setFieldValue('goalId', response.data.id);
        }
      } catch (error) {
        console.log('Error fetching goal:', error);
      }
    }
  };

  const fetchGoalUrgentData = async () => {
    if (selectedSubCategory) {
      try {
        const response = await axios.get<subCategoryType>(`/api/goals/urgent/${selectedSubCategory}`);
        if (response?.data) {
          setSelectedGoal(response.data);
          formik.setFieldValue('goalId', response.data.id);
        }
      } catch (error) {
        console.log('Error fetching goal:', error);
      }
    }
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const { category } = useParams();
  useEffect(() => {
    if (category !== undefined) {
      setSelectedCategoryId(category);
      formik.setFieldValue('categoryID', category);
    }
  }, [category]);
  useEffect(() => {
    if (formik.values.subCategoryID) {
      formik.setFieldValue('subCategoryID', formik.values.subCategoryID);
      setSelectedSelectedSubCategory(formik.values.subCategoryID);
      formik.setFieldValue('lostPet.subCategoryID', formik.values.subCategoryID);
    }
  }, [formik.values.subCategoryId]);

  const fetchSubCategory = async (id: string) => {
    try {
      const response = await axios.get<subCategoryType[]>(`api/activity/subcategory/${id}`);
      setSubCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBloodTypes = async () => {
    try {
      const response = await axios.get<bloodType[]>(`api/activity/blood-types`);
      setBloodTypes(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values?.subCategoryID) {
      fetchBloodTypes();
    }
  }, [formik.values?.subCategoryID]);
  useEffect(() => {
    if (selectedCategoryId !== undefined) {
      fetchSubCategory(selectedCategoryId);
      // fetchBloodTypes();
    }
    if (formik.values.subCategoryID !== undefined) {
      formik.setFieldValue('subCategoryID', formik.values.subCategoryID);
      setSelectedSelectedSubCategory(formik.values.subCategoryID);
      formik.setFieldValue('lostPet.subCategoryID', formik.values.subCategoryID);
      fetchGoalUrgentData();
    }
    if (selectedSubCategory !== undefined) {
      fetchActivityData(selectedSubCategory);
      setSelectedActivityId(formik.values.activityTypeId);
    }
    if (selectedActivityId !== undefined) {
      fetchGoalData(selectedActivityId);
    }
    if (formik.values.activityTypeId !== undefined) {
      formik.setFieldValue('activityTypeId', formik.values.activityTypeId);
      setSelectedActivityId(formik.values.activityTypeId);
    }

    if (formik.values.goalId !== undefined) {
      formik.setFieldValue('goalId', formik.values.goalId);
    }
    if (formik.values.quantity !== undefined) {
      formik.setFieldValue('quantity', formik.values.quantity);
    }
  }, [
    selectedCategoryId,
    selectedSubCategory,
    selectedActivityId,
    formik.values.categoryID,
    formik.values.subCategoryId,
    formik.values.activityTypeId,
    formik.values.goalId,
    formik.values.quantity,
  ]);

  useEffect(() => {
    localStorage.removeItem('categoryIdCheck');
    if (formik.values.categoryID) {
      setSelectedCategoryId(formik.values.categoryID);
    }
  }, [formik.values.categoryID]);

  useEffect(() => {
    if (formik.values.subCategoryID) {
      setSelectedSelectedSubCategory(formik.values.subCategoryID);
      formik.setFieldValue('lostPet.subCategoryID', formik.values.subCategoryID);
    }

    if (formik.values.bloodTypeId) {
      setSelectedBloodType(formik.values.bloodTypeId);
    }
  }, [formik.values.subCategoryID, formik.values.bloodTypeId]);
  const input = document.querySelector('input[name="quantity"]');

  if (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
  }

  document.addEventListener('DOMContentLoaded', function () {
    const input1 = document.querySelector('input[name="units"]');

    if (input1) {
      input1.addEventListener('wheel', function (event) {
        event.preventDefault();
      });
    }
  });
  const selectedCategory = sideBaricons.find(category => category.id === formik.values.categoryID);
  useEffect(() => {
    if (selectedCategory && selectedCategory.id === formik.values.categoryID) {
      const svgElements = document.querySelectorAll('svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1m9ymud-MuiSvgIcon-root');
      svgElements.forEach(svgElement => {
        const svgElementAsHtmlElement = svgElement as HTMLElement;
        svgElementAsHtmlElement.style.fill = '#676BFF';
      });
    }
  }, [selectedCategory, formik.values.categoryID]);
  console.log('formik.errors', formik.errors);
  return (
    <Stack direction="column" gap={3}>
      <style>
        {`.css-1udluuh-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
          color:rgb(20 22 26);
          -webkit-text-fill-color:unset
        }`}
        {`
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-13wovh5-MuiInputBase-root-MuiOutlinedInput-root {
border-radius: 15px 0px 0px 15px;}`}

        {`.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
        border-radius: 15px 0px 0px 15px;
        }`}
        {`.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
                       padding-bottom:8px !important;
                      font-size: 0.75rem !important;
          }`}

        {`.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-1lq73yz-MuiInputBase-root-MuiOutlinedInput-root {
           border-radius: 0px 15px 15px 0px !important;
        }`}

        {`.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-18a9ajd-MuiInputBase-root-MuiOutlinedInput-root {
           border-radius: 0px 15px 15px 0px !important;
        }`}

        {`.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-8j7gsl-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 0px 15px 15px 0px !important;
        }`}

        {`.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-1d8emzi-MuiInputBase-root-MuiOutlinedInput-root {
         border-radius: 0px 15px 15px 0px !important;
        }`}
        {
          `.css-j7o0ot-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
         border-radius: 0px 15px 15px 0px !important;
          }`
        }
        {
          `.css-tj4od5-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled{
            border-radius: 0px 15px 15px 0px !important;
          }`
        }
        {
          `.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
          border-radius: 15px 0px 0px 15px  !important;
          }`
        }
        {
          `.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-1o8bnng-MuiInputBase-root-MuiOutlinedInput-root{
           border-radius: 0px 15px 15px 0px !important;
          }`
        }
        {
          `.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-lddo5v-MuiInputBase-root-MuiOutlinedInput-root{
           border-radius: 0px 15px 15px 0px !important;
          }`
        }
      </style>
      <Grid
        container
        direction="row"
        sx={{ marginTop: '10px' }}
        gap={{ xs: 2.2, md: 2 }}
        alignItems={'baseline'}
        justifyContent={'space-between'}
      >
        <Grid item xs={12} md>
          <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', borderRadius: '15px' }}>
            <Select
              size="small"
              name="categoryID"
              fullWidth
              displayEmpty
              placeholder="Select Category *"
              labelId="select-categories"
              id="select-categories"
              value={formik.values.categoryID}
              onChange={handleSelectCategory}
              disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
              title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
              // error={formik.touched.categoryID && Boolean(formik.errors.categoryID)}
              startAdornment={
                !formik.values.categoryID ? (
                  <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                    <DashboardOutlinedIcon fontSize="small" sx={{ mr: 2, color: theme.textColor.paragraph }} />
                    <span style={{ fontSize: '.75rem',  color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                         opacity: 1, }}>Category*</span>
                  </div>
                ) : null
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    overflowY: 'scroll',
                  },
                },
                MenuListProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
              sx={{
                border: 'none',
                '&.MuiMenu-paper': {
                  borderRadius: '15px',
                  border: 'none',
                },
                '&.MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: 45,
                  border: 'none',
                  '& fieldset': {
                    border: '1px solid #BFBFBF',
                  },
                },
              }}
            >
              {categories?.map((category: any) => {
                const matchingIcon = sideBaricons.find(iconData => iconData.id === category.id);
                return (
                  <MenuItem value={category.id} key={category.id}>
                    <Stack direction={'row'} pl={1} gap={1}>
                      {matchingIcon?.icon}
                      <Typography sx={{ fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {category.category}
                      </Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {formik.touched.categoryID && formik.errors.categoryID && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.categoryID && formik.errors.categoryID}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md>
          <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', color: 'black', borderRadius: '15px' }}>
            <Select
              name="subCategoryID"
              fullWidth
              displayEmpty
              size="small"
              placeholder="Sub Category"
              labelId="sub-category"
              id="sub-category"
              value={selectedSubCategory}
              onChange={handleSelectSubCategory}
              startAdornment={
                !formik.values.subCategoryID ? (
                  <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                    <span style={{ fontSize: '.75rem', color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                         opacity: 1, }}>Subcategory*</span>
                  </div>
                ) : null
              }
              disabled={formik.values.volunteerParticipant > 1 || formik.values.child || !formik.values.categoryID}
              title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
              // error={formik.touched.subCategoryID && Boolean(formik.errors.subCategoryID)}
              MenuProps={{
                PaperProps: {
                  style: {
                    overflowY: 'scroll',
                  },
                },
                MenuListProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
              sx={{
                border: 'none',
                '&.MuiMenu-paper': {
                  backgroundColor: '#FF5500',
                  borderRadius: '15px',
                  border: 'none',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  height: 45,
                  border: 'none',
                  '& fieldset': {
                    border: '1px solid #BFBFBF',
                  },
                },
                '&:focus-within, &:hover': {
                  border: 'none',
                },
              }}
            >
              {subCategories?.map((category: any) => (
                <MenuItem value={category.id} key={category.id}>
                  <Stack direction={'row'} pl={1} gap={0.2}>
                    <Typography sx={{ fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {category.label}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.subCategoryID && formik.errors.subCategoryID && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.subCategoryID && formik.errors.subCategoryID}
            </Typography>
          )}
        </Grid>
      </Grid>
      {formik.values.categoryID === 19 && formik?.values?.subCategoryID !== 14 && (
        <Grid
          container
          direction="row"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          gap={{ xs: 2.2, md: 2 }}
          sx={{ marginBottom: '10px', flexWrap: 'wrap' }}
        >
          {/* If no finance goal is selected */}
          {!selectedGoal?.finance && (
            <>
              <Grid item xs={12} md>
                <FormControl
                  fullWidth
                  sx={{ backgroundColor: 'transparent', color: 'secondary.main', borderRadius: '15px' }}
                  variant="outlined"
                >
                  <OutlinedInput
                    name="quantity"
                    onChange={e => {
                      const value = e.target.value;

                      // Only allow numeric values and max 5 digits
                      if (/^\d*$/.test(value)) {
                        formik.setFieldValue('quantity', value);
                      }
                    }}
                    inputProps={{ maxLength: 5 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantity}
                    autoComplete="off"
                    sx={{
                      height: '45px',
                      fontSize: '.75rem',
                      color:
                        !selectedGoal || formik.values.volunteerParticipant >= 2 || formik.values.child
                          ? 'secondary.contrastText'
                          : 'secondary.secondary.main',
                      border: !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child ? 'none' : '1px solid #BFBFBF',
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                        opacity: 1,
                      },
                      '& .MuiOutlinedInput-root': {
                        background:
                          !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child
                            ? 'secondary.contrastText'
                            : 'secondary.main',
                        borderRadius: '15px',
                        border: 'none',
                      },
                      '&:focus-within,&:hover': {
                        border: 'none',
                      },
                      '& fieldset': {
                        borderColor: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'transparent',
                      },
                    }}
                    size="small"
                    type={'text'}
                    placeholder={selectedGoal?.label ? `Expected goal: ${selectedGoal.label}*` : 'Expected goal*'}
                    disabled={!selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child}
                    title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                  />
                </FormControl>
                {formik.touched.quantity && formik.errors.quantity && (
                  <Typography variant="caption" sx={{ color: 'error.main' }}>
                    {formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''}
                  </Typography>
                )}
              </Grid>
            </>
          )}

          {/* If a finance goal is selected */}
          {selectedGoal?.finance && (
            <>
              <Grid container direction="row" gap={{ xs: 0, md: 0 }} alignItems={'baseline'} justifyContent={'space-between'}>
                <Grid item xs={3} md={3}>
                  <FormControl
                    fullWidth
                    sx={{
                      backgroundColor: 'transparent', color: 'secondary.main', borderRadius: '15px 0px 0px 15px',
                      border: 'none',
                      '& fieldset': {
                        border: '1px solid #BFBFBF',
                      },
                    }}
                    variant="outlined"
                  >
                    <Autocomplete
                      options={countryCodes}
                      getOptionLabel={option => `${option.code}`}
                      filterOptions={(options, { inputValue }) =>
                        options.filter(option => option.code.toLowerCase().includes(inputValue.toLowerCase()))
                      }
                      onChange={(e, value) => formik.setFieldValue('currency', value?.code || '')}
                      value={countryCodes.find(option => option.code === (formik.values.currency || 'USD')) || null}
                      disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                      title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                      renderInput={params => <TextField name={`currency`} {...params} label="" size="small" placeholder="Currency code" />}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                            alt=""
                          />
                          {option.code}
                        </Box>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={9} md={9}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: 'transparent', color: 'secondary.main', borderRadius: '0px 15px 15px 0px' }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      name="quantity"
                      onChange={e => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          formik.setFieldValue('quantity', value);
                        }
                      }}
                      inputProps={{ maxLength: 5 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                      autoComplete="off"
                      sx={{
                        height: '40px',
                        fontSize: '.75rem',
                        color:
                          !selectedGoal || formik.values.volunteerParticipant >= 2 || formik.values.child
                            ? 'secondary.contrastText'
                            : 'secondary.secondary.main',
                        border:
                          !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child ? 'none' : '1px solid #BFBFBF',
                        '&.MuiOutlinedInput-input::placeholder': {
                          color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                          opacity: 1,
                        },
                        '&.MuiOutlinedInput-root': {
                          background:
                            !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child
                              ? 'secondary.contrastText'
                              : 'secondary.main',
                          borderRadius: '0px 15px 15px 0px',
                          border: 'none',
                        },
                        '&:focus-within,&:hover': {
                          border: 'none',
                        },
                        '& fieldset': {
                          borderColor: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'transparent',
                        },
                      }}
                      size="small"
                      type={'text'}
                      placeholder={selectedGoal?.label ? `Expected goal: ${selectedGoal.label}*` : 'Expected goal*'}
                      disabled={!selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child}
                      title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                    />
                  </FormControl>
                  {formik.touched.quantity && formik.errors.quantity && (
                    <Typography variant="caption" sx={{ color: 'error.main' }}>
                      {formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
      {formik.values.categoryID !== 19 && formik.values.subCategoryID !== 72 && (
        <Grid
          container
          direction="row"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          gap={{ xs: 2.2, md: 2 }}
          sx={{ marginBottom: '10px', flexWrap: 'wrap' }}
        >
          <Grid item xs={12} md={formik.values?.activityTypeId?.length < 25 ? 6 : 12}  
          sx={{
             '@media (max-width:1400px)': {
                      flexBasis:formik.values?.activityTypeId?.length < 25 && formik.values?.activityTypeId ?'50%': '49%',
                         },
            }}>
            <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', color: 'black', borderRadius: '15px' }}>
              <Select
                size="small"
                name="activityTypeId"
                fullWidth
                displayEmpty
                placeholder="Type of activity *"
                labelId="Type of activity"
                id="Type of activity"
                startAdornment={
                  !formik.values?.activityTypeId ? (
                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                      <span style={{ fontSize: '.75rem', color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                         opacity: 1 }}>Type of activity*</span>
                    </div>
                  ) : null
                }
                value={formik.values.activityTypeId}
                onChange={handleActivityType}
                disabled={formik.values.volunteerParticipant >= 2 || formik.values.child || !formik.values.subCategoryID }
                title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                MenuProps={{
                  PaperProps: {
                    style: {
                      overflowY: 'scroll',
                    },
                  },
                  MenuListProps: {
                    style: {
                      maxHeight: '200px',
                    },
                  },
                }}
                sx={{
                  border: 'none',
                  '&.MuiMenu-paper': {
                    borderRadius: '15px',
                    border: 'none',
                  },
                  '&.MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    height: 45,
                    border: 'none',
                    '& fieldset': {
                      border: '1px solid #BFBFBF',
                    },
                  },
                  '&:focus-within, &:hover': {
                    border: 'none',
                  },
                }}
              >
                {selectedTypeOfActivity?.map((category: any) => {
                  return (
                    <MenuItem value={category.id} key={category.id}>
                      <Stack direction={'row'} pl={1} gap={0.2}>
                        <Typography sx={{ fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {category.label}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formik.touched.activityTypeId && formik.errors.activityTypeId && (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                {formik.touched.activityTypeId && formik.errors.activityTypeId}
              </Typography>
            )}
          </Grid>

          {!selectedGoal?.finance && (
            <>
              <Grid item xs={12} md>
                <FormControl
                  fullWidth
                  sx={{ backgroundColor: 'transparent', color: 'secondary.main', borderRadius: '15px' }}
                  variant="outlined"
                >
                  <OutlinedInput
                    name="quantity"
                    onChange={e => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        formik.setFieldValue('quantity', value);
                      }
                    }}
                    inputProps={{ maxLength: 5 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantity}
                    autoComplete="off"
                    sx={{
                      height: '45px',
                      fontSize: '.75rem',
                      color:
                        !selectedGoal || formik.values.volunteerParticipant >= 2 || formik.values.child
                          ? 'secondary.contrastText'
                          : 'secondary.secondary.main',
                      border: !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child ? 'none' : '1px solid #BFBFBF',
                      '& input::placeholder': {
                         color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                         opacity: 1,
                      },
                      '& .MuiOutlinedInput-root': {
                        background:
                          !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child
                            ? 'secondary.contrastText'
                            : 'secondary.main',
                        borderRadius: '15px',
                        border: 'none',
                      },
                      '&:focus-within,&:hover': {
                        border: 'none',
                      },
                      '& fieldset': {
                        borderColor: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'transparent',
                      },
                    }}
                    size="small"
                    type={'text'}
                    placeholder={selectedGoal?.label ? `Expected goal: ${selectedGoal.label}*` : 'Expected goal*'}
                    disabled={!selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child}
                    title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                  />
                </FormControl>
                {formik.touched.quantity && formik.errors.quantity && (
                  <Typography variant="caption" sx={{ color: 'error.main' }}>
                    {formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''}
                  </Typography>
                )}
              </Grid>
            </>
          )}

          {selectedGoal?.finance && (
            <>
              <Grid container direction="row" gap={{ xs: 0, md: 0 }} alignItems={'baseline'} justifyContent={'space-between'}>
                <Grid item xs={3} md={3}>
                  <FormControl
                    fullWidth
                    sx={{
                      backgroundColor: 'transparent', color: 'secondary.main', borderRadius: '15px 0px 0px 15px',
                      border: 'none',
                      '& fieldset': {
                        border: '1px solid #BFBFBF',
                      },
                    }}
                    variant="outlined"
                  >
                    <Autocomplete
                      options={countryCodes}
                      getOptionLabel={option => `${option.code}`}
                      filterOptions={(options, { inputValue }) => {
                        return options.filter(option => option.code.toLowerCase().includes(inputValue.toLowerCase()));
                      }}
                      onChange={(e, value) => {
                        formik.setFieldValue('currency', value?.code || '');
                      }}
                      value={countryCodes.find(option => option.code === (formik.values.currency || 'USD')) || null}
                      disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                      title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                      renderInput={params => <TextField name={`currency`} {...params} label="" size="small" placeholder="Currency code" />}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                            alt=""
                          />
                          {option.code}
                        </Box>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={9} md={9}>
                  <FormControl
                    fullWidth
                    sx={{ backgroundColor: 'transparent', color: 'secondary.main', borderRadius: '0px 15px 15px 0px' }}
                    variant="outlined"
                  >
                    <OutlinedInput
                      name="quantity"
                      onChange={e => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          formik.setFieldValue('quantity', value);
                        }
                      }}
                      inputProps={{ maxLength: 5 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                      autoComplete="off"
                      sx={{
                        height: '40px',
                        fontSize: '.75rem',
                        color:
                          !selectedGoal || formik.values.volunteerParticipant >= 2 || formik.values.child
                            ? '#949494'
                            : 'secondary.secondary.main',
                        border:
                          !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child ? 'none' : '1px solid #BFBFBF',
                          '& input::placeholder': {
                            color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                            opacity: 1,
                          },
                        '& .MuiOutlinedInput-root': {
                          background:
                            !selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child
                              ? 'secondary.contrastText'
                              : 'secondary.main',
                          borderRadius: '0px 15px 15px 0px',
                          border: 'none',
                        },
                        '&:focus-within,&:hover': {
                          border: 'none',
                        },
                        '& fieldset': {
                          borderColor: isDarkMode.darkMode ? '1px solid #BFBFBF' : 'transparent',
                        },
                      }}
                      size="small"
                      type={'text'}
                      placeholder={selectedGoal?.label ? `Expected goal: ${selectedGoal.label}*` : 'Expected goal*'}
                      disabled={!selectedGoal || formik.values.volunteerParticipant > 1 || formik.values.child}
                      title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                    />
                  </FormControl>
                  {formik.touched.quantity && formik.errors.quantity && (
                    <Typography variant="caption" sx={{ color: 'error.main' }}>
                      {formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : ''}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}

      {formik.values.subCategoryID === 14 && (
        <Grid
          container
          direction="row"
          alignItems={'baseline'}
          justifyContent={'space-between'}
          gap={{ xs: 2.2, md: 2 }}
          sx={{ marginBottom: '10px', flexWrap: 'wrap' }}
        >
          <Grid item xs={12} md>
            <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', color: 'black', borderRadius: '15px' }}>
              <Select
                size="small"
                name="bloodTypeId"
                fullWidth
                displayEmpty
                placeholder="Blood group *"
                labelId="Blood group"
                id="Blood group"
                value={selectedBloodType}
                onChange={handleSelectBloodType}
                startAdornment={
                  !formik.values?.bloodTypeId ? (
                    <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                      <span style={{ fontSize: '.75rem', color: isDarkMode.darkMode ? '#a6a6a6' : '#dadada' }}>Blood group*</span>
                    </div>
                  ) : null
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      overflowY: 'scroll',
                    },
                  },
                  MenuListProps: {
                    style: {
                      maxHeight: '200px',
                    },
                  },
                }}
                sx={{
                  border: 'none',
                  '&.MuiMenu-paper': {
                    borderRadius: '15px',
                    border: 'none',
                  },
                  '&.MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    height: 45,
                  },
                  '&:focus-within, &:hover': {
                    border: 'none',
                  },
                }}
              >
                {bloodTypes?.map((type: any) => (
                  <MenuItem value={type.id} key={type.id}>
                    <Stack direction={'row'} pl={1} gap={0.2}>
                      <Typography sx={{ fontSize: '.75rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {type.bloodType}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.touched.bloodTypeId && formik.errors.bloodTypeId && (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                {formik.touched.bloodTypeId && formik.errors.bloodTypeId}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md>
            <FormControl
              fullWidth
              sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }}
              variant="outlined"
            >
              <OutlinedInput
                name="units"
                onChange={event => {
                  const value = event.target.value;
                  // Prevent entering zero as the first number
                  if (value.startsWith('0') && value.length === 1) {
                    formik.setFieldValue('units', ''); // Clear input if only '0' is entered
                  } else if (value !== '') {
                    const numericValue = parseInt(value, 10);
                    formik.setFieldValue('units', numericValue > 0 ? numericValue : ''); // Set numeric value or clear if it's non-numeric or zero
                  } else {
                    formik.setFieldValue('units', ''); // Handle empty string scenario
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.units}
                error={formik.touched.units && Boolean(formik.errors.units)}
                autoComplete="off"
                sx={{
                  fontSize: '12px',
                  height: '45px',
                  color: formik.values.volunteerParticipant > 1 ? 'secondary.contrastText' : 'secondary.secondary.main',
                  border: formik.values.volunteerParticipant > 1 || formik.values.child ? 'none' : '1px solid #BFBFBF',
                  '& .MuiOutlinedInput-input::placeholder': {
                    color: isDarkMode.darkMode ? theme.placeholder.dark : theme.placeholder.light,
                    opacity: 1,
                  },
                  '& .MuiOutlinedInput-root': {
                    background: formik.values.volunteerParticipant > 1 ? 'secondary.contrastText' : 'secondary.main',
                    borderRadius: '15px',
                    border: 'none',
                  },
                  '&:focus-within, &:hover': {
                    border: 'none',
                  },
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                }}
                size="small"
                type={'text'}
                placeholder="Number of units*"
                disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                title={formik.values.child ? "This field is not editable in single occurrences of a recurring event" : ""}
                onKeyDown={event => {
                  if (event.key === 'Backspace' || event.key === 'Tab') {
                    return true;
                  }
                  if (!/^[0-9]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FormControl>
            {formik.touched.units && formik.errors.units && (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                {formik.touched.units && formik.errors.units ? formik.errors.units : ''}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default CreateActivitySelectCategory;
