import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface ParticipantType {
  data: string;
}

export interface ParticipantState {
  status: ParticipantType | null;
  loading: null | boolean;
  success: null | boolean;
  error: null | string;
}

export const initialState: ParticipantState = {
  status: null,
  loading: null,
  success: null,
  error: null,
};

export const reportParticipant = createAsyncThunk<any, { userID?: any; activityID?: any; reason?: any }, { rejectValue: string }>(
  'api/report-participant',
  async ({ userID, activityID, reason }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/activity-details/report-participant', { userID, activityID, reason });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const blockParticipant = createAsyncThunk<any, { userId?: any; activityId?: any; message?: any }, { rejectValue: string }>(
  'api/block-participant',
  async ({ userId, activityId, message }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/activity-details/block-participant', { userId, activityId, message });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const makeModerator = createAsyncThunk<any, { userId?: any; activityId?: any }, { rejectValue: string }>(
  'api/make-moderator',
  async ({ userId, activityId }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/activity-details/make-moderator', { userId, activityId });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const changeParticipant = createAsyncThunk<any, { userId?: any; activityId?: any; participantType?: any }, { rejectValue: string }>(
  'api/switch-participant-role',
  async ({ userId, activityId, participantType }, { rejectWithValue }) => {
    try {
      const response = await axios.put<any>('/api/participants/switch-participant-role', { userId, activityId, participantType });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const participantSlice = createSlice({
  name: 'activityarticipants',
  initialState: initialState as ParticipantState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(reportParticipant.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(reportParticipant.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.status = action.payload;
      state.error = null;
    });

    builder.addCase(reportParticipant.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });

    builder.addCase(blockParticipant.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(blockParticipant.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.status = action.payload;
      state.error = null;
    });

    builder.addCase(blockParticipant.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });
    builder.addCase(makeModerator.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(makeModerator.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.status = action.payload;
      state.error = null;
    });

    builder.addCase(makeModerator.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });
  },
});

export default participantSlice.reducer;
