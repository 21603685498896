import axios, { AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { getFirebaseToken } from 'app/firebase';
import { toast } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUserPreferencesData, sendUserPreferencesData } from 'app/shared/reducers/user-preferences';
import { clearExistingActivities } from './activity-filter';
import { jwtDecode } from 'jwt-decode';

export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
const USER_PREFERENCES = 'userPreferencesCompleted';
const myCookieValue = Cookies.get('jhi-authenticationToken');
const token = myCookieValue ? myCookieValue : Storage.local.get('jhi-authenticationToken');

export const initialState = {
  loading: false,
  isAuthenticated: token ? true : false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  userPreferencesDone: false,
  banned: false,
  responseObj: {} as any,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions
export const toggleModalLogin = (): AppThunk => dispatch => {
  dispatch(AuthenticationSlice.actions.toggleModalLogin());
};

export const getSession = (): AppThunk => (dispatch, getState) => {
  dispatch(getAccount());
  dispatch(getUserPreferencesData());
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

interface registerParams {
  deviceId: string;
  fcmTokenId: string;
}
interface IAuthParams {
  email: string;
  password: string;
  rememberMe?: boolean;
}
interface GSocialLoginParams {
  code: string;
}

const scheduleTokenRefresh = jwtToken => {
  try {
    const expirationTime = 86400;
    const time = localStorage.getItem('tokenExpiryTime') ? Number(localStorage.getItem('tokenExpiryTime')) : expirationTime;
    console.log('schedule api response', time);
  } catch (error) {
    console.error('Error decoding JWT token:', error);
  }
};

export const GoogleSocialLogin = createAsyncThunk(
  'authentication/social_login',
  async (params: GSocialLoginParams, { rejectWithValue, dispatch }) => {
    try {
      const result = await axios.post<any>('api/account/socialLogin', { ...params, provider: 'GOOGLE', platform: 'web' });
      if (result?.request?.status === 200 || result?.request?.status === 201) {
        console.log('gmail response', result);
        fetchPostingRules();
        localStorage.setItem('openOnce', JSON.stringify('once'));
        localStorage.setItem('Banned', result?.data?.banned);
        const userPreferecesDone = result?.data?.isUserPreferenceDone;
        Storage.session.set(USER_PREFERENCES, userPreferecesDone === true);
        const bearerToken = result?.data?.jwtToken?.id_token;
        localStorage.setItem('refreshToken', result?.data?.refreshToken);
        localStorage.setItem('tokenExpiryTime', result?.data?.tokenExpiryTime);
        Cookies.set('jhi-authenticationToken', bearerToken);
        Storage.local.set(AUTH_TOKEN_KEY, result.data?.jwtToken?.id_token);
        Storage.session.set(AUTH_TOKEN_KEY, result.data?.jwtToken?.id_token);
        scheduleTokenRefresh(bearerToken);
        if (bearerToken && result) {
          dispatch(getSession());
          handleGetFirebaseToken();
        }
      }
    } catch (error) {
      return rejectWithValue(serializeAxiosError(error));
    }
  }
);

export const AppleSocialLogin = createAsyncThunk('authentication/socialLogin', async (params: any, { rejectWithValue, dispatch }) => {
  try {
    const { code, idToken, email, firstName, lastName } = params;
    const result = await axios.post<any>('api/account/socialLogin', {
      code,
      name: `${firstName || ''} ${lastName || ''}`.trim(),
      provider: 'APPLE',
      platform: 'web',
    });
    if (result?.request?.status === 200 || result?.request?.status === 201) {
      console.log('apple response', result);
      fetchPostingRules();
      localStorage.setItem('openOnce', JSON.stringify('once'));
      localStorage.setItem('Banned', result?.data?.banned);
      const userPreferecesDone = result?.data?.isUserPreferenceDone;
      Storage.session.set(USER_PREFERENCES, userPreferecesDone === true);
      const bearerToken = result?.data?.jwtToken?.id_token;
      localStorage.setItem('refreshToken', result?.data?.refreshToken);
      localStorage.setItem('tokenExpiryTime', result?.data?.tokenExpiryTime);
      Cookies.set('jhi-authenticationToken', bearerToken);
      Storage.local.set(AUTH_TOKEN_KEY, result.data?.jwtToken?.id_token);
      Storage.session.set(AUTH_TOKEN_KEY, result.data?.jwtToken?.id_token);
      scheduleTokenRefresh(bearerToken);
      if (bearerToken && result) {
        dispatch(getSession());
        handleGetFirebaseToken();
      }
    }
  } catch (error) {
    return rejectWithValue(serializeAxiosError(error));
  }
});

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) => axios.post<any>('api/authenticate', auth),
  {
    serializeError: serializeAxiosError,
  }
);

const registerDevice = async () => {
  try {
    const randomDeviceId = `device-${Math.floor(Math.random() * 10000)}-${Date.now()}`;
    localStorage.setItem('randomDeviceId', randomDeviceId);
    let params = { deviceId: randomDeviceId, fcmTokenId: JSON.parse(localStorage.getItem('firebaseToken')) };
    const response = await axios.post<any>('api/users/register-device', params);
  } catch (error) {
    console.log('Error fetching register device:', error);
  }
};

export const unregisterDevice = createAsyncThunk(
  'register-device-firebase',
  async (params: registerParams, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/users/logout', params);
      localStorage.clear();
      sessionStorage.clear();
      toast.success(response.data.message);
      return response;
    } catch (error) {
      return rejectWithValue(serializeAxiosError(error));
    }
  },
  {
    serializeError: serializeAxiosError,
  }
);
export const login: (email: string, password: string, rememberMe?: boolean) => AppThunk =
  (email, password, rememberMe = false) =>
  async dispatch => {
    const result = await dispatch(authenticate({ email, password, rememberMe }));
    const response = result.payload as AxiosResponse;
    if (response?.status === 200 || response?.status === 201) {
      localStorage.setItem('openOnce', JSON.stringify('once'));
      fetchPostingRules();
      const banned = response.data.banned;
      localStorage.setItem('Banned', response?.data?.banned);
      const userPreferecesDone = response.data.isUserPreferenceDone;
      Storage.session.set(USER_PREFERENCES, userPreferecesDone === true);
      const bearerToken = response.data?.jwtToken?.id_token;
      localStorage.setItem('refreshToken', response?.data?.refreshToken);
      localStorage.setItem('tokenExpiryTime', response?.data?.tokenExpiryTime);
      Cookies.set('jhi-authenticationToken', bearerToken);
      Storage.local.set(AUTH_TOKEN_KEY, response.data?.jwtToken?.id_token);
      Storage.session.set(AUTH_TOKEN_KEY, response.data?.jwtToken?.id_token);
      scheduleTokenRefresh(bearerToken);
      if (bearerToken && result) {
        dispatch(clearExistingActivities());
        dispatch(getSession());
        handleGetFirebaseToken();
        // fetchPostingRules();
      }
    }
  };

const fetchPostingRules = async () => {
  try {
    const response = await axios.get<postingRulesType[]>('api/volunteer_instructions');
    Storage.local.set('postingRules', response.data);
  } catch (error) {
    console.log(error);
  }
};

export const getPhoneNumber = createAsyncThunk<any, void, { rejectValue: string }>(
  'phone/getPhoneNumber',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>('api/userPhone');
      if (response?.data) {
        Storage.local.set('getPhone', response.data);
      }
      return response?.data;
    } catch (error) {
      console.log(error, 'error');
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);
const handleGetFirebaseToken = () => {
  getFirebaseToken()
    .then(firebaseToken => {
      Storage.local.set('firebaseToken', firebaseToken);
      if (firebaseToken) {
        registerDevice();
      }
    })
    .catch(err => console.error('An error occured while retrieving firebase token. ', err));
};

export const clearAuthToken = () => {
  sessionStorage.clear();
  const keyToPreserve = 'dark-mode';
  for (const key in localStorage) {
    if (localStorage.hasOwnProperty(key) && key !== keyToPreserve) {
      localStorage.removeItem(key);
    }
  }
};

export const logout: () => AppThunk = () => async dispatch => {
  Cookies.remove('jhi-authenticationToken');

  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach(cookieName => {
    Cookies.remove(cookieName);
  });
  clearAuthToken();

  await clearBrowserCache();
  dispatch(logoutSession());
};
async function clearBrowserCache() {
  if ('caches' in window) {
    const cacheKeys = await caches.keys();
    for (const cacheName of cacheKeys) {
      await caches.delete(cacheName);
    }
    console.log('All browser caches have been cleared.');
  }
}
export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    toggleModalLogin: state => {
      return {
        ...state,
        showModalLogin: true,
      };
    },
    setAuthenticationFalse() {
      return {
        ...initialState,
        isAuthenticated: false,
      };
    },
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        isAuthenticated: false,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
    resetLogin(state) {
      return {
        ...state,
        loginError: false,
        errorMessage: null,
      };
    },
    setAuthentication(state) {
      const localToken = Storage.local.get(AUTH_TOKEN_KEY);
      const sessionToken = Storage.session.get(AUTH_TOKEN_KEY);
      const myCookieValue = Cookies.get('jhi-authenticationToken');
      const authenticationToken = myCookieValue;
      const isCookie = !!authenticationToken;

      state.isAuthenticated = !!localToken || !!sessionToken || isCookie;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authenticate.rejected, (state, action) => {
        let errorMessage = action.error.message;

        // Check if it's a network error
        if (action.error.message && action.error.message.includes('Network Error')) {
          errorMessage = 'Unable to connect. Please check your internet connection and try again.';
        }
        return {
          ...initialState,
          errorMessage: errorMessage,
          showModalLogin: true,
          isAuthenticated: false,
          loginError: true,
          userPreferencesDone: false,
          loginSuccess: false,
        };
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          loginError: false,
          showModalLogin: false,
          loginSuccess: true,
          isAuthenticated: true,
          userPreferencesDone: action.payload.data.isUserPreferenceDone,
          banned: action.payload.data.banned,
          responseObj: action.payload.data,
        };
      })
      .addCase(getAccount.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          sessionHasBeenFetched: false,
          showModalLogin: true,
          errorMessage: action.error.message,
        };
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        localStorage.setItem('MyDetailsAccount', JSON.stringify(action.payload.data));
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(authenticate.pending, state => ({
        ...initialState,
        loading: true,
        showModalLogin: true,
      }))
      .addCase(GoogleSocialLogin.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        isAuthenticated: true,
        userPreferencesDone: true,
      }))
      .addCase(GoogleSocialLogin.rejected, (state, action) => ({
        ...initialState,
        isAuthenticated: false,
        showModalLogin: true,
        errorMessage: action.error.message,
        loginError: true,
        loginSuccess: false,
        userPreferencesDone: false,
      }))
      .addCase(AppleSocialLogin.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
        isAuthenticated: true,
        userPreferencesDone: true,
      }))
      .addCase(AppleSocialLogin.rejected, (state, action) => ({
        ...initialState,
        isAuthenticated: false,
        showModalLogin: true,
        errorMessage: action.error.message,
        loginError: true,
        loginSuccess: false,
        userPreferencesDone: false,
      }));
  },
});

export const { logoutSession, authError, clearAuth, resetLogin, setAuthentication, setAuthenticationFalse } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
