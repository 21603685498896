import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import ReportParticipantDialog from 'app/components/common/modals/participant-report-modal/participant-report-modal';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ParticipantMenu from '../participant-menu/participant-menu';
import { makeModerator } from 'app/shared/reducers/participants';
import toast from 'react-hot-toast';

function ParticipantList({ activityResponse, onParticipantBlocked, changeCredentials }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [anchorE2, setAnchorE2] = React.useState<HTMLButtonElement | null>(null);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [action, setAction] = useState('');
  const [userId, setuserId] = useState('');
  const [participantReportDialogOpen, setparticipantReportDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleOptionChange = (option, id) => {
    if (option === 'report-participant') {
      setTitle('Report Participant');
      setMessage('Please provide a reason for reporting the participant.');
      setAction('Report');
      setuserId(id);
    } else if (option === 'block-participant') {
      setTitle('Block Participant');
      setMessage('Please choose one');
      setAction('Block');
      setuserId(id);
    }
    setparticipantReportDialogOpen(true);
  };

  const reportParticipant = (id: any) => {
    handleOptionChange('report-participant', id);
  };

  const blockParticipant = (id: any) => {
    handleOptionChange('block-participant', id);
  };
  const changeParticpants = (type: any, id: any) => {
    console.log('type', type);
    console.log('id', id);
    changeCredentials(type, id);
  };
  const makeParticipantModerator = async (id: any) => {
    try {
      const response = await dispatch(makeModerator({ userId: id, activityId: activityResponse.id }));

      if (response.meta.requestStatus === 'fulfilled') {
        toast.success(response.payload);
      } else {
        toast.error(response.payload);
      }
    } catch (error) {
      console.error('An error occured', error);
      toast.error('Something went wrong!!');
    }
  };
  const viewProfile = (participant: any) => {
    navigate(`/profile/${participant?.name?.replace(/\s+/g, '-')}-${participant?.id}`);
  };
  return (
    <div>
      <Grid container padding={'16px'}>
        {activityResponse?.activityStatus === 'COMPLETED' &&
          activityResponse?.participants?.some(participant => !participant.organizer && !participant.moderator && participant.reached) &&
          ''}

        {activityResponse?.participants &&
          activityResponse?.activityStatus === 'OPEN' &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator) &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator).length > 0 && (
            <Grid container spacing={2} sx={{ marginLeft: '16px' }}>
              {['VOLUNTEER', 'ATTENDEE'].map(type => {
                const filteredParticipants = activityResponse?.participants?.filter(
                  participant => !participant.organizer && !participant.moderator && participant.participantType === type
                );

                return (
                  filteredParticipants.length > 0 && (
                    <Grid item xs={12} key={type}>
                      <Typography
                        sx={{
                          color: theme.textColor.mainHeading,
                          fontSize: '20px/32px',
                          paddingBottom: '16px',
                          fontWeight: 'normal',
                        }}
                      >
                        {type === 'VOLUNTEER' ? 'Volunteers' : 'Attendees'}
                      </Typography>
                      <Grid container spacing={2}>
                        {filteredParticipants.map(participant => (
                          <Grid key={participant.id} item xs={6} sm={6} md={4} lg={3} xl={2}>
                            <Stack alignItems="center" justifyContent="center">
                              <Box position="relative">
                                <Avatar
                                  alt={participant.name}
                                  src={participant.profilePic || 'default-avatar.jpg'}
                                  sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                  onClick={() =>
                                    participant.organizationId
                                      ? navigate(`/organization/${participant?.name?.replace(/\s+/g, '-')}-${participant.organizationId}`)
                                      : viewProfile(participant)
                                  }
                                />
                                <Box sx={{ position: 'absolute', top: 0, left: '90%' }}>
                                  {isAuthenticated && (
                                    <ParticipantMenu
                                      activityResponse={activityResponse}
                                      participant={participant}
                                      onReport={() => reportParticipant(participant.id)}
                                      onBlock={() => blockParticipant(participant.id)}
                                      onMakeModerator={() => makeParticipantModerator(participant.id)}
                                      onViewProfile={() => viewProfile(participant)}
                                      id={participant.id}
                                      onMakeParticipant={changeParticpants}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  wordWrap: 'break-word',
                                  whiteSpace: 'normal',
                                  color: theme.palette.text.primary,
                                  fontSize: theme.typography.pxToRem(14),
                                  fontWeight: theme.typography.fontWeightRegular,
                                  width: '100%',
                                }}
                              >
                                <div>
                                  {participant.name}
                                  <br />
                                  <span style={{ fontSize: '0.8em', color: 'grey' }}>{participant?.participantType}</span>
                                </div>
                              </Typography>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}

        {activityResponse?.participants &&
          activityResponse?.activityStatus === 'RUNNING' &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator) &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator).length > 0 && (
            <>
              <Grid container spacing={2} sx={{ marginLeft: '16px' }}>
                {['VOLUNTEER', 'ATTENDEE'].map(type => {
                  const typeParticipants = activityResponse?.participants?.filter(
                    participant => !participant.organizer && !participant.moderator && participant.participantType === type
                  );

                  return (
                    typeParticipants.length > 0 && (
                      <Grid item xs={12} key={type}>
                        <Typography
                          sx={{
                            color: theme.textColor.mainHeading,
                            fontSize: '20px/32px',
                            paddingBottom: '16px',
                            fontWeight: 'normal',
                          }}
                        >
                          {type === 'VOLUNTEER' ? 'Volunteers' : 'Attendees'}
                        </Typography>

                        {['Arrived', 'Awaiting Arrival'].map((status, index) => {
                          const filteredParticipants = typeParticipants.filter(
                            p => (status === 'Arrived' && p.reached) || (status === 'Awaiting Arrival' && !p.reached)
                          );

                          return (
                            filteredParticipants.length > 0 && (
                              <React.Fragment key={status}>
                                {index !== 0 && <Divider sx={{ width: '100%', color: '#292D34' }} />}
                                <Typography
                                  sx={{
                                    color: status === 'Arrived' ? '#058847' : '#FF9494',
                                    fontSize: '14px',
                                    paddingTop: '16px',
                                    paddingBottom: '8px',
                                  }}
                                >
                                  {status}
                                </Typography>
                                <Grid container spacing={2}>
                                  {filteredParticipants.map(participant => (
                                    <Grid key={participant.id} item xs={6} sm={6} md={4} lg={3} xl={2}>
                                      <Stack alignItems="center" justifyContent="center">
                                        <Box position="relative">
                                          <Avatar
                                            alt={participant.name}
                                            src={participant.profilePic || 'default-avatar.jpg'}
                                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                            onClick={() =>
                                              participant.organizationId
                                                ? navigate(
                                                    `/organization/${participant?.name?.replace(/\s+/g, '-')}-${participant.organizationId}`
                                                  )
                                                : viewProfile(participant)
                                            }
                                          />
                                          <Box sx={{ position: 'absolute', top: 0, left: '90%' }}>
                                            {isAuthenticated && (
                                              <ParticipantMenu
                                                activityResponse={activityResponse}
                                                participant={participant}
                                                onReport={() => reportParticipant(participant.id)}
                                                onBlock={() => blockParticipant(participant.id)}
                                                onMakeModerator={() => makeParticipantModerator(participant.id)}
                                                onViewProfile={() => viewProfile(participant)}
                                                id={participant.id}
                                                onMakeParticipant={changeParticpants}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                        <Typography
                                          sx={{
                                            textAlign: 'center',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal',
                                            color: theme.palette.text.primary,
                                            fontSize: theme.typography.pxToRem(14),
                                            fontWeight: theme.typography.fontWeightRegular,
                                            width: '100%',
                                          }}
                                        >
                                          <div>
                                            {participant.name}
                                            <br />
                                            <span style={{ fontSize: '0.8em', color: 'grey' }}>{participant?.participantType}</span>
                                          </div>
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  ))}
                                </Grid>
                              </React.Fragment>
                            )
                          );
                        })}
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </>
          )}

        {activityResponse?.participants &&
          activityResponse?.activityStatus === 'ENDED' &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator) &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator).length > 0 && (
            <>
              <Grid container spacing={2} sx={{ marginLeft: '16px' }}>
                {['VOLUNTEER', 'ATTENDEE'].map(type => {
                  const typeParticipants = activityResponse?.participants?.filter(
                    participant => !participant.organizer && !participant.moderator && participant.participantType === type
                  );

                  return (
                    typeParticipants.length > 0 && (
                      <Grid item xs={12} key={type}>
                        <Typography
                          sx={{
                            color: theme.textColor.mainHeading,
                            fontSize: '20px/32px',
                            paddingBottom: '16px',
                            fontWeight: 'normal',
                          }}
                        >
                          {type === 'VOLUNTEER' ? 'Volunteers' : 'Attendees'}
                        </Typography>

                        {['Arrived', 'Not Arrived'].map((status, index) => {
                          const filteredParticipants = typeParticipants.filter(
                            p => (status === 'Arrived' && p.reached) || (status === 'Not Arrived' && !p.reached)
                          );

                          return (
                            filteredParticipants.length > 0 && (
                              <React.Fragment key={status}>
                                {index !== 0 && <Divider sx={{ width: '100%', color: '#292D34' }} />}
                                <Typography
                                  sx={{
                                    color: status === 'Arrived' ? '#058847' : '#FF9494',
                                    fontSize: '14px',
                                    paddingTop: '16px',
                                    paddingBottom: '8px',
                                  }}
                                >
                                  {status}
                                </Typography>
                                <Grid container spacing={2}>
                                  {filteredParticipants.map(participant => (
                                    <Grid key={participant.id} item xs={6} sm={6} md={4} lg={3} xl={2}>
                                      <Stack alignItems="center" justifyContent="center">
                                        <Box position="relative">
                                          <Avatar
                                            alt={participant.name}
                                            src={participant.profilePic || 'default-avatar.jpg'}
                                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                            onClick={() =>
                                              participant.organizationId
                                                ? navigate(
                                                    `/organization/${participant?.name?.replace(/\s+/g, '-')}-${participant.organizationId}`
                                                  )
                                                : viewProfile(participant)
                                            }
                                          />
                                          <Box sx={{ position: 'absolute', top: 0, left: '90%' }}>
                                            {isAuthenticated && (
                                              <ParticipantMenu
                                                activityResponse={activityResponse}
                                                participant={participant}
                                                onReport={() => reportParticipant(participant.id)}
                                                onBlock={() => blockParticipant(participant.id)}
                                                onMakeModerator={() => makeParticipantModerator(participant.id)}
                                                onViewProfile={() => viewProfile(participant)}
                                                id={participant.id}
                                                onMakeParticipant={changeParticpants}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                        <Typography
                                          sx={{
                                            textAlign: 'center',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal',
                                            color: theme.palette.text.primary,
                                            fontSize: theme.typography.pxToRem(14),
                                            fontWeight: theme.typography.fontWeightRegular,
                                            width: '100%',
                                          }}
                                        >
                                          <div>
                                            {participant.name}
                                            <br />
                                            <span style={{ fontSize: '0.8em', color: 'grey' }}>{participant?.participantType}</span>
                                          </div>
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  ))}
                                </Grid>
                              </React.Fragment>
                            )
                          );
                        })}
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </>
          )}

        {activityResponse?.participants &&
          activityResponse?.activityStatus === 'COMPLETED' &&
          activityResponse?.participants?.filter(participant => !participant.organizer && !participant.moderator) && (
            <Grid container spacing={2} sx={{ marginLeft: '16px' }}>
              {['VOLUNTEER', 'ATTENDEE'].map(type => {
                const typeParticipants = activityResponse?.participants?.filter(
                  participant =>
                    !participant.organizer && !participant.moderator && participant.participantType === type && participant.reached
                );

                return (
                  typeParticipants.length > 0 && (
                    <Grid item xs={12} key={type}>
                      <Typography
                        sx={{
                          color: theme.textColor.mainHeading,
                          fontSize: '20px/32px',
                          paddingBottom: '16px',
                          fontWeight: 'normal',
                        }}
                      >
                        {type === 'VOLUNTEER' ? 'Volunteers' : 'Attendees'}
                      </Typography>

                      {['Arrived'].map((status, index) => {
                        const filteredParticipants = typeParticipants.filter(p => status === 'Arrived' && p.reached);

                        return (
                          filteredParticipants.length > 0 && (
                            <React.Fragment key={status}>
                              {index !== 0 && <Divider sx={{ width: '100%', color: '#292D34' }} />}
                              <Typography
                                sx={{
                                  color: '#058847',
                                  fontSize: '14px',
                                  paddingTop: '16px',
                                  paddingBottom: '8px',
                                }}
                              >
                                {status}
                              </Typography>
                              <Grid container spacing={2}>
                                {filteredParticipants.map(participant => (
                                  <Grid key={participant.id} item xs={6} sm={6} md={4} lg={3} xl={2}>
                                    <Stack alignItems="center" justifyContent="center">
                                      <Box position="relative">
                                        <Avatar
                                          alt={participant.name}
                                          src={participant.profilePic || 'default-avatar.jpg'}
                                          sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                          onClick={() =>
                                            participant.organizationId
                                              ? navigate(
                                                  `/organization/${participant?.name?.replace(/\s+/g, '-')}-${participant.organizationId}`
                                                )
                                              : viewProfile(participant)
                                          }
                                        />
                                        <Box sx={{ position: 'absolute', top: 0, left: '90%' }}>
                                          {isAuthenticated && (
                                            <ParticipantMenu
                                              activityResponse={activityResponse}
                                              participant={participant}
                                              onReport={() => reportParticipant(participant.id)}
                                              onBlock={() => blockParticipant(participant.id)}
                                              onMakeModerator={() => makeParticipantModerator(participant.id)}
                                              onViewProfile={() => viewProfile(participant)}
                                              id={participant.id}
                                              onMakeParticipant={changeParticpants}
                                            />
                                          )}
                                        </Box>
                                      </Box>
                                      <Typography
                                        sx={{
                                          textAlign: 'center',
                                          wordWrap: 'break-word',
                                          whiteSpace: 'normal',
                                          color: theme.palette.text.primary,
                                          fontSize: theme.typography.pxToRem(14),
                                          fontWeight: theme.typography.fontWeightRegular,
                                          width: '100%',
                                        }}
                                      >
                                        <div>
                                          {participant.name}
                                          <br />
                                          <span style={{ fontSize: '0.8em', color: 'grey' }}>{participant?.participantType}</span>
                                        </div>
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                ))}
                              </Grid>
                            </React.Fragment>
                          )
                        );
                      })}
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}
      </Grid>

      <ReportParticipantDialog
        open={participantReportDialogOpen}
        onClose={() => setparticipantReportDialogOpen(false)}
        handleData={activityResponse}
        title={title}
        message={message}
        action={action}
        userId={userId}
        onParticipantBlocked={onParticipantBlocked}
      />
    </div>
  );
}

export default ParticipantList;
