import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useAppSelector } from 'app/config/store';
import { Grid, Typography, useTheme } from '@mui/material';

interface SettingsProfileFirstNameProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<{}>>;
}

const SettingsProfileFirstName = ({ setUpdatedProfile }: SettingsProfileFirstNameProps) => {

  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);
  const [firstName, setFirstName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    if (profileSettings?.firstName) {
      setFirstName(profileSettings?.firstName);
    }
    return () => {
      setFirstName('');
    };
  }, [profileSettings?.firstName]);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.target.value;
    // Allow only alphabets
    const validatedData = data.replace(/[^a-zA-Z]/g, '');
    setFirstName(validatedData);
    if (validatedData === '') {
      setErrorMessage('First name field cannot be empty');
    } else {
      setErrorMessage('');
    }
    setUpdatedProfile(state => ({ ...state, firstName: validatedData }));
  };
  const theme = useTheme();   
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Enter your first name.
        </Typography >
      </Grid>
      <Grid item xs={12} className="pt-3">
        <TextField size="small" fullWidth name="firstName" placeholder="First name" value={firstName} onChange={handleFirstNameChange} inputProps={{
          style: {
            fontSize: "0.75rem",
            padding: "12.5px 14px"

          }
        }}
        
        sx={{
          "& .MuiInputBase-input::placeholder": {
            color: isDarkMode?  theme.placeholder.dark:theme.placeholder.light,
            opacity: 1, 
            },
        }}/>
        {errorMessage && (
          <div style={{ margin: '10px 0px', fontFamily: 'Manrope', fontWeight: '400', fontSize: '12px', color: ' #E23A44' }}>
            {errorMessage}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default SettingsProfileFirstName;
