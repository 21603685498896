import dayjs from 'dayjs';
import * as Yup from 'yup';

export const createPetitionSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Petition title is required.')
    .min(1, 'Petition title is required to be at least 1 character.')
    .max(75, 'Petition title cannot be longer than 75 characters.'),
    // .matches(/^[^/?\\]*$/, 'Petition title cannot contain the "?", "/", or "\\" character.'),
  details: Yup.string()
    .trim()
    .required('Petition details is required')
    .max(3910, 'Petition details cannot exceed 4000 characters, including spaces'),
  categoryID: Yup.string().required('Category is required'),
  targetSignature: Yup.number().required('Target signature is required').min(1, 'Number cannot be zero'),
  multimedia: Yup.array().required('At least one multimedia item is required.').min(1, 'At least one multimedia item is required.'),
  petitionTo: Yup.array().of(Yup.string()).min(1, 'At least one recipient is required.').required('At least one recipient is required.'),
  startDate: Yup.date()
    .required('Start date is required')
    .min(dayjs().startOf('day').toDate(), 'Start date must be on or after today')
    .max(Yup.ref('endDate'), 'Start date must be before the end date'),

  endDate: Yup.date().required('End date is required').min(Yup.ref('startDate'), 'End date must be after the start date'),
  latitude: Yup.string(),
  longitude: Yup.string(),
});

export const editPetitionSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Petition title is required.')
    .min(1, 'Petition title is required to be at least 1 character.')
    .max(75, 'Petition title cannot be longer than 75 characters.'),
    // .matches(/^[^/?\\]*$/, 'Petition title cannot contain the "?", "/", or "\\" character.'),
  details: Yup.string()
    .trim()
    .required('Petition details is required')
    .max(3910, 'Petition details cannot exceed 4000 characters, including spaces'),
  categoryID: Yup.string().required('Category is required'),
  targetSignature: Yup.number().required('Target signature is required').min(1, 'Number cannot be zero'),
  multimedia: Yup.array().required('At least one multimedia item is required.').min(1, 'At least one multimedia item is required.'),
  urgent: Yup.boolean(),
  postAsOrganisationId: Yup.boolean(),
  petitionTo: Yup.array().of(Yup.string()).min(1, 'At least one recipient is required.').required('At least one recipient is required.'),
  startDate: Yup.date()
    .required('Start date is required')
    .test('is-future-date', 'Start date cannot be in the past', function (value) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return value >= today;
    })
    .max(Yup.ref('endDate'), 'Start date must be before the end date'),
  endDate: Yup.date().required('End date is required').min(Yup.ref('startDate'), 'End date must be after the start date'),
  latitude: Yup.string(),
  longitude: Yup.string(),
});
