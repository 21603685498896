import { useEffect } from 'react';

function useMetaTagUpdater(activity) {
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaOgTitle = document.querySelector('meta[property="og:title"]');
    const metaOgDescription = document.querySelector('meta[property="og:description"]');
    const pageTitle = document.querySelector('title');
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    const twitterDescription = document.querySelector('meta[name="twitter:description"]');
    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    const twitterCard = document.querySelector('meta[name="twitter:card"]');
    const metaImage = document.querySelector('meta[property="og:image"]');
    const allMetaDescription = document.querySelectorAll('meta[name="description"]');
    const allMetaTwitterTitle = document.querySelectorAll('meta[name="twitter:title"]');
    const allMetaTwitterDescription = document.querySelectorAll('meta[name="twitter:description"]');
    const allMetaTwitterImage = document.querySelectorAll('meta[name="twitter:image"]');
    const allMetaOgDescription = document.querySelectorAll('meta[property="og:description"]');
    const allMetaOgTitle = document.querySelectorAll('meta[property="og:title"]');

    // Remove duplicate meta tags
    const removeDuplicates = (metaCollection) => {
        if (metaCollection.length > 1) {
            metaCollection.forEach((meta, index) => {
                if (index > 0) {
                    meta.parentNode.removeChild(meta);
                }
            });
        }
    };

    removeDuplicates(allMetaDescription);
    removeDuplicates(allMetaOgDescription);
    removeDuplicates(allMetaTwitterDescription);
    removeDuplicates(allMetaTwitterTitle);
    removeDuplicates(allMetaTwitterImage);
    removeDuplicates(allMetaOgTitle);

    // Update meta tags
    if (metaDescription) {
        metaDescription.setAttribute("content", activity.caption);
    }

    if (pageTitle) {
        pageTitle.textContent = activity.title;
    }

    if (metaImage) {
        metaImage.setAttribute("content", activity?.eventGallery[0]?.link);
    }

    if (metaOgDescription) {
        metaOgDescription.setAttribute("content", activity.caption);
    }

    if (metaOgTitle) {
        metaOgTitle.setAttribute("content", activity.title);
    }

    if (twitterTitle) {
        twitterTitle.setAttribute("content", activity.title);
    } else {
        const twitterTitleTag = document.createElement('meta');
        twitterTitleTag.setAttribute('name', 'twitter:title');
        twitterTitleTag.setAttribute('content', activity.title);
        document.head.appendChild(twitterTitleTag);
    }

    if (twitterDescription) {
        twitterDescription.setAttribute("content", activity.caption);
    } else {
        const twitterDescTag = document.createElement('meta');
        twitterDescTag.setAttribute('name', 'twitter:description');
        twitterDescTag.setAttribute('content', activity.caption);
        document.head.appendChild(twitterDescTag);
    }

    if (twitterImage) {
        twitterImage.setAttribute("content", activity?.eventGallery[0]?.link);
    } else {
        const twitterImageTag = document.createElement('meta');
        twitterImageTag.setAttribute('name', 'twitter:image');
        twitterImageTag.setAttribute('content', activity?.eventGallery[0]?.link);
        document.head.appendChild(twitterImageTag);
    }

    if (twitterCard) {
        twitterCard.setAttribute("content", "summary_large_image");
    } else {
        const twitterCardTag = document.createElement('meta');
        twitterCardTag.setAttribute('name', 'twitter:card');
        twitterCardTag.setAttribute('content', "summary_large_image");
        document.head.appendChild(twitterCardTag);
    }

}

export default useMetaTagUpdater;
