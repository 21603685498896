import React, { useState } from 'react';
import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { changeParticipant } from 'app/shared/reducers/participants';

function ParticipantMenu({ participant, onReport, onBlock, onMakeParticipant, onMakeModerator, onViewProfile, activityResponse, id }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = useAppSelector(state => state.authentication.account.id);
  const dispatch = useAppDispatch();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReport = () => {
    onReport(participant.id);
    handleClose();
  };

  const handleBlock = () => {
    onBlock(participant.id);
    handleClose();
  };

  const handleModerator = () => {
    onMakeModerator(participant.id);
    handleClose();
  };

  const handleParticipant = async () => {
    console.log('activityResponse', activityResponse);
    const response = await dispatch(
      changeParticipant({
        userId: participant?.id,
        activityId: activityResponse.id,
        participantType: participant?.participantType === 'VOLUNTEER' ? 'ATTENDEE' : 'VOLUNTEER',
      })
    );
    if (response) onMakeParticipant(participant?.participantType === 'VOLUNTEER' ? 'ATTENDEE' : 'VOLUNTEER', participant.id);
    handleClose();
  };

  const handleViewProfile = () => {
    onViewProfile(participant.id);
    handleClose();
  };
  console.log('participant', participant);
  return (
    <div>
      <IconButton disableRipple size="small" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {userId !== id &&
          (participant?.reported ? (
            <MenuItem onClick={() => {}}>
              <ListItemText primary="Reported" />
            </MenuItem>
          ) : (
            <MenuItem onClick={handleReport}>
              <ListItemText primary="Report participant" />
            </MenuItem>
          ))}
        {userId !== id && !participant?.reached && (
          <MenuItem onClick={handleBlock}>
            <ListItemText primary="Block participant" />
          </MenuItem>
        )}
        {activityResponse?.hasPermission &&
          (activityResponse?.activityStatus === 'OPEN' || activityResponse?.activityStatus === 'RUNNING') && (
            <MenuItem onClick={handleModerator}>
              <ListItemText primary="Make Moderator" />
            </MenuItem>
          )}

        {/* {activityResponse?.hasPermission &&
          activityResponse?.participantOption === 'BOTH' &&
          (activityResponse?.activityStatus === 'OPEN' || activityResponse?.activityStatus === 'RUNNING') && (
            <MenuItem onClick={handleParticipant}>
              <ListItemText primary={`Make ${participant?.participantType === 'VOLUNTEER' ? 'Attendee' : 'Volunteer'}`} />
            </MenuItem>
          )} */}
        <MenuItem onClick={handleViewProfile}>
          <ListItemText primary="View profile" />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ParticipantMenu;
