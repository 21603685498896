import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { logout, logoutSession, unregisterDevice } from 'app/shared/reducers/authentication';
import { clearSearchKeyword, clearSearhedActivities } from 'app/shared/reducers/activity-filter';
import { clearUserPreferences } from 'app/shared/reducers/user-preferences';
import { clearPhoneVerification } from 'app/shared/reducers/phone-verification';
import { useNavigate } from 'react-router-dom';

const SettingsChangeEmail = ({ setUpdatedSettings }: any) => {
  const userSettings = useAppSelector(state => state.settingsReducer.userSettings);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isValidEmail, setValidEmail] = useState(true);
  const [issameError, setsameError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const handleDialogOpen = () => {
    setDialogOpen(true);
    setEmail('');
    setPassword('');
  };

  const handleDialogClose = () => {
    if (success) {
      dispatch(logout());
      dispatch(clearSearchKeyword());
      dispatch(clearSearhedActivities());
      dispatch(clearUserPreferences());
      dispatch(clearPhoneVerification());
      Cookies.remove('jhi-authenticationToken');
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    }
    /**
     *logout success toast will be from back end response
     */
    setDialogOpen(false);
    setValidEmail(true);
  };
  useEffect(() => {
    setEmailInput(email);
  }, [email]);

  const handleSaveChanges = async () => {
    let payload = {
      email: emailCheck,
      password: password,
    };
    try {
      const response = await axios.put<any>('api/profile/settings/update-email', payload);
      if (response.status === 201 || response.status === 200) {
        // setEmail(emailCheck);
        // handleDialogClose();
        setPasswordCorrect(false);
        setSuccess(true);
      }
    } catch (error) {
      console.log(' error', error);
      if (error?.response.data.message == 'error.emailexists') {
        setsameError(true);
      }
    }
  };

  const validateEmail = (email: any) => {
    setEmailCheck(email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);
    if (isEmailValid) {
      setValidEmail(true);
      setEmailCheck(email);
    } else {
      setValidEmail(false);
    }
  };

  const checkPassword = async () => {
    // Add logic to check if the entered password is correct
    // For simplicity, let's assume the correct password is "examplePassword"

    let payload = {
      email: userSettings?.emailId,
      password: password,
    };
    try {
      const response = await axios.put<any>('api/profile/settings/update-email', payload);
      if (response.status === 201 || response.status === 200) {
        setPasswordCorrect(true);
        setPasswordError(false);
      }
    } catch (error) {
      console.log(' error', error);
      if (error?.response.data?.message == 'error.emailexists') {
        setPasswordCorrect(true);
        setPasswordError(false);
      } else {
        setPasswordCorrect(false);
        setPasswordError(true);
      }
    }
  };

  return (
    <Grid container direction="row" alignItems="center" justifyContent={'flex-start'}>
      <Grid container item md={4} xs={6} direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
            Contact email
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: theme.textColor.heading }}>{emailInput ? emailInput : userSettings?.emailId}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6} md>
        <Grid item container direction="column">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            disableRipple
            sx={{ gap: 1, justifyContent: 'flex-end' }}
            onClick={handleDialogOpen}
          >
            <Typography
              sx={{
                color: theme.textColor.mainHeading,
                textTransform: 'none',
                borderRadius: 1.875,
                py: 0.5,
                px: 3.25,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                fontSize: theme.infoText.regular.fontSize,
                fontWeight: theme.infoText.regular.fontWeight,
                fontStyle: theme.infoText.regular.fontStyle,
              }}
             
            >
              Change
            </Typography>
          </IconButton>
        </Grid>
      </Grid>

      {/* Dialog Box */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} sx={{ '& .MuiDialog-paper': { borderRadius: '15px', width: '100%' } }}>
        {!passwordCorrect && !success && <DialogTitle>Enter your current password</DialogTitle>}
        {passwordCorrect && <DialogTitle>Enter your new email</DialogTitle>}
        <DialogContent>
          <Box mb={2} mt={1}>
            {!passwordCorrect && !success && (
              <>
                <TextField
                  label="Password"
                  type={password?.length > 0 ? 'password' : 'text'}
                  fullWidth
                  margin="normal"
                  value={password}
                  error={isPasswordError}
                  onChange={e => {
                    setPasswordError(false);
                    setPassword(e.target.value);
                  }}
                  autoComplete="off"
                />

                {isPasswordError && <FormHelperText error>Entered password is incorrect.</FormHelperText>}
              </>
            )}

            {success && (
              <Typography sx={{ color: isDarkMode ? '#fff' : 'rgba(0, 0, 0, 0.6)', fontFamily: 'sans-serif', lineHeight: 1.5 }}>
                {' '}
                Please check the verification email sent to your new email address and follow the instructions. Please login again for the
                changes to take effect.
              </Typography>
            )}
          </Box>
          <Box mb={2} mt={1}>
            {passwordCorrect && (
              <>
                <TextField
                  label="New Email"
                  fullWidth
                  margin="normal"
                  value={emailCheck}
                  onChange={e => validateEmail(e.target.value)}
                  error={!isValidEmail || issameError}
                />
                {!isValidEmail && <FormHelperText error>Invalid email format</FormHelperText>}
                {issameError && <FormHelperText error>Your new email is same as current email</FormHelperText>}
                <Box textAlign={'end'}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: 'primary.light',
                      color: '#ffffff',
                      borderRadius: '4px',
                      ' &:hover': {
                        backgroundColor: 'primary.light',
                      },
                    }}
                    onClick={handleSaveChanges}
                    disabled={!isValidEmail}
                  >
                    Proceed
                  </Button>
                </Box>
              </>
            )}
          </Box>
          <Box textAlign={'end'}>
            {!passwordCorrect && !success && (
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'primary.light',
                  color: '#ffffff',
                  borderRadius: '4px',
                  ' &:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
                onClick={checkPassword}
              >
                Verify & proceed
              </Button>
            )}
            {success && (
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'primary.light',
                  color: '#ffffff',
                  borderRadius: '4px',
                  ' &:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
                onClick={handleDialogClose}
              >
                Ok
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default SettingsChangeEmail;
