import { useAppSelector } from 'app/config/store';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface StaticGmapProps {
  lat: number | string;
  lng: number | string;
  height?: number | string;
  width?: number | string;
}

const StaticGMapImage = ({ lat, lng, height = '164px', width = '100%' }: StaticGmapProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [mapUrl, setMapUrl] = useState('');

  useEffect(() => {
    const fetchMapImage = async () => {
      try {
        let response;

        if (isAuthenticated) {
          response = await axios.post('/api/proxy-map', { lat, lng }, { responseType: 'blob' });
        } else {
          response = await axios.post('/api/unauthorized/proxy-map', { lat, lng }, { responseType: 'blob' });
        }

        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = () => {
          setMapUrl(reader.result as string);
        };
      } catch (error) {
        console.error('Error fetching map image:', error);
      }
    };

    fetchMapImage();
  }, [lat, lng, isAuthenticated]);

  return (
    <img
      src={mapUrl}
      alt={`Location of ${lat},${lng}`}
      height={height}
      width={width}
    />
  );
};

export default StaticGMapImage;
