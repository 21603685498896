import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';

interface EventRequest {
  eventId: number;
  eventType: string;
}

export interface chatMessageState {
  chatResponse: any | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
  lastEventRequest: EventRequest | null;
}

export const initialState: chatMessageState = {
  chatResponse: null,
  loading: false,
  error: null,
  success: false,
  lastEventRequest: null,
};
let isLoading = false;

export const getAllConversationsById = createAsyncThunk<any, EventRequest, { rejectValue: string }>(
  '/api/chats/conversations',
  async (eventRequest, { rejectWithValue }) => {
    try {
      if (isLoading) {
        return;
      }
      isLoading = true;
      const axiosResponse = await axios.post<any>('/api/chats/conversations', eventRequest);
      isLoading = false;
      return axiosResponse.data;
    } catch (error) {
      isLoading = false;
      throw rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const getPinnedMessage = createAsyncThunk<any, { messageId: string; payload: EventRequest }, { rejectValue: string }>(
  '/api/chats/conversation/pin/{messageId}',
  async ({ messageId, payload }, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.post<any>(`/api/chats/conversation/pin/${messageId}`, payload);
      return axiosResponse.data;
    } catch (error) {
      throw rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const chatPanelSlice = createSlice({
  name: 'chatPanelReducer',
  initialState,
  reducers: {
    setConversations(state, action) {
      state.chatResponse = action.payload;
    },
    addMessage(state, action) {
      state.chatResponse.push(action.payload);
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAllConversationsById.pending, (state, action) => {
        // state.chatResponse = [];
        if (!_.isEqual(state.lastEventRequest, action.meta.arg)) {
          state.chatResponse = []; // Reset to empty array if eventRequest changes
          state.loading = true;
        }
        state.lastEventRequest = action.meta.arg;

        state.error = null;
        state.success = false;
      })
      .addCase(getAllConversationsById.fulfilled, (state, action) => {
        state.chatResponse = action.payload;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getAllConversationsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.success = false;
      })
      .addCase(getPinnedMessage.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getPinnedMessage.fulfilled, (state, action) => {
        state.chatResponse = action.payload;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getPinnedMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.success = false;
      });
  },
});
export const { setConversations, addMessage } = chatPanelSlice.actions;
export default chatPanelSlice.reducer;
