import React, { useState, useEffect } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './settings-profile-prof.scss';
import { useAppSelector } from 'app/config/store';

interface SettingsProfilePreferredTimeProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<{}>>;
}

const SettingsProfilePreferredTime = ({ setUpdatedProfile }: SettingsProfilePreferredTimeProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [preferredStartTime, setPreferredStartTime] = useState<Date | null>(null);
  const [preferredEndTime, setPreferredEndTime] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);

  useEffect(() => {
    if (profileSettings?.preferredEndTime) {
      setPreferredEndTime(new Date(profileSettings?.preferredEndTime));
    }
  }, [profileSettings?.preferredEndTime]);

  useEffect(() => {
    if (profileSettings?.preferredStartTime) {
      setPreferredStartTime(new Date(profileSettings?.preferredStartTime));
    }
  }, [profileSettings?.preferredStartTime]);

  const validateTimes = (start: Date | null, end: Date | null) => {
    console.log('start time', start);
    console.log(' end time', end);
    if (start && end) {
      if (start.getTime() >= end.getTime()) {
        setError('Start time must be less than end time.');
        return false;
      }
    }
    setError(null);
    return true;
    setError(null);
    return true;
  };

  const handleStartTimeChange = (date: Date | null) => {
    if (validateTimes(date, preferredEndTime)) {
      setPreferredStartTime(date);
      setUpdatedProfile(state => ({ ...state, preferredStartTime: date }));
    }
  };

  const handleEndTimeChange = (date: Date | null) => {
    if (validateTimes(preferredStartTime, date)) {
      setPreferredEndTime(date);
      setUpdatedProfile(state => ({ ...state, preferredEndTime: date }));
    }
  };

  return (
    <Grid item container direction="column" gap={3} justifyContent={'center'}>
      <Grid item>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Choose your preferred time
        </Typography>
      </Grid>
      <style>
        {`.react-datepicker__time-container .react-datepicker__time{
          background-color: ${isDarkMode ? '#1F1F26 !important' : '#fff !important'};
          color: ${isDarkMode ? '#fff !important' : '#1F1F26 !important'}
        }
        `}
        {`.react-datepicker__header:not(.react-datepicker__header--has-time-select){
          background-color: ${isDarkMode ? '#1F1F26 !important' : '#fff !important'};
           border-bottom: none !important;        
        }
        `}
        {
          `input.rom-timepicker {
  font-size: 0.75rem;
}`
        }
      </style>

      <Grid
        item
        container
        direction={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        maxWidth={'300px'}
        gap={3}
      >
        <Grid item xs={12} md>
          <DatePicker
            className={isDarkMode ? 'rom-timepickerdark ' : 'rom-timepicker '}
            selected={preferredStartTime}
            onChange={handleStartTimeChange}
            showTimeSelect
            showTimeSelectOnly
            fontSize={12}
            timeIntervals={15}
            timeCaption=""
            dateFormat="h:mm aa"
            isClearable={false}
            placeholderText="Start time"
            onKeyDown={e => {
              e.preventDefault();
            }}
            
          />
        </Grid>
        <Grid item xs={12} md>
          <DatePicker
            className={isDarkMode ? 'rom-timepickerdark ' : 'rom-timepicker '}
            selected={preferredEndTime}
            onChange={handleEndTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption=""
            dateFormat="h:mm aa"
            isClearable={false}
            placeholderText="End time"
            onKeyDown={e => {
              e.preventDefault();
            }}
          />
        </Grid>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SettingsProfilePreferredTime;
