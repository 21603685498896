// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#demo-multiple-chip {
  font-size: 0.75rem;
}

.interest .MuiOutlinedInput-root {
  height: 45px; /* Default height */
}

.interest.interest-expanded .MuiOutlinedInput-root {
  height: auto !important; /* Auto height when more than 5 items are selected */
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-profile/settings-profile-intrests/settings-profile-intrests-list.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,YAAA,EAAA,mBAAA;AAEJ;;AACE;EACE,uBAAA,EAAA,oDAAA;AAEJ","sourcesContent":["#demo-multiple-chip {\n    font-size: 0.75rem;\n}\n.interest .MuiOutlinedInput-root {\n    height: 45px; /* Default height */\n  }\n  \n  .interest.interest-expanded .MuiOutlinedInput-root {\n    height: auto !important; /* Auto height when more than 5 items are selected */\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
