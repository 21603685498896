import React from 'react';
import { Grid, TextField, Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'app/config/store';

interface SettingsProfileBioProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<{}>>;
}
const SettingsProfileBio = ({ setUpdatedProfile }: SettingsProfileBioProps) => {
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Enter your bio.
        </Typography>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <TextField
          name="bio"
          fullWidth
          id="bio"
          defaultValue={profileSettings?.bio || ''}
          rows={4}
          placeholder="Enter your short bio"
          inputProps={{
            style: {
              fontSize: "0.75rem",
              padding: "0px 0px"

            }
          }}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              color: isDarkMode?  theme.placeholder.dark:theme.placeholder.light,
              opacity: 1, 
              },
          }}
          multiline
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const bioValue = e.target.value;
            setUpdatedProfile(prevState => ({
              ...prevState,
              bio: bioValue,
            }));
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsProfileBio;
