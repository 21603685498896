import { FormControl, Grid, OutlinedInput, Typography } from '@mui/material';
import React, { useState } from 'react';

type Props = {
  organizationName: string;
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  hasPermission: boolean;
  check?: boolean;
};

const SettingsOrgSelectedName = ({ organizationName, handleDetailsChange, hasPermission, check }: Props) => {
  const [error, setError] = useState<string | null>(null);
  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Name
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <OutlinedInput
            placeholder="Organization name"
            sx={{ height: '40px', fontSize: '0.75rem' }}
            id="outlined-adornment-weight"
            onChange={e => handleDetailsChange('organizationName', e.target.value)}
            value={organizationName}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            disabled={!hasPermission}
          />
        </FormControl>
        {!check && <span style={{ color: '#E23A44', fontSize: '0.75rem' }}>Organization name must be between 2 and 50 characters long and cannot contain the characters '?', '/', or '\'.</span>}
      </Grid>
    </Grid>
  );
};

export default SettingsOrgSelectedName;
