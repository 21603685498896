import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { fetchCategories } from 'app/shared/reducers/categories';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SettingsProfileType } from 'app/utils/types/settings/settings-profile-types';
import './settings-profile-intrests-list.scss'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: '200px',
      width: 250,
      top: '12px',
    },
  },
};



function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

interface SettingsProfileIntrestsProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<SettingsProfileType>>;
}
const settingsProfileIntrestsList = ({ setUpdatedProfile }: SettingsProfileIntrestsProps) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);
  const [categories, setCategories] = useState<any>([]);
  const dispatch = useAppDispatch();
  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    const newPersonName = typeof value === 'string' ? value.split(',') : value;

    setPersonName(newPersonName);
    setUpdatedProfile((prevState) => ({
      ...prevState,
      interests: newPersonName ? newPersonName.map(Number) : [],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = (await dispatch(fetchCategories())) as any;
        setCategories(response?.payload?.map((item: any) => ({ id: item?.id, category: item?.category })));


      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [profileSettings]);
  useEffect(() => {
    if (profileSettings && profileSettings.interests) {
      const newPersonName = profileSettings.interests.map((interestId: number) => {
        const matchingCategory = categories.find((category: any) => category.id === interestId);
        return matchingCategory ? matchingCategory.id : null;
      }).filter(id => id !== null);
      setPersonName(newPersonName);

      setUpdatedProfile((prevState) => ({
        ...prevState,
        interests: newPersonName ? newPersonName : [],
      }));
    }
  }, [profileSettings, categories])
  return (
    <div >
      <FormControl className={`interest ${personName.length > 5 ? 'interest-expanded' : ''}`} sx={{ m: 1, width: '100%',  height: personName.length > 5 ? 'auto' : '45px', }}>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxWidth: '100%', }}>
              {selected?.map(value => {
                const matchingCategory = categories?.find(category => category.id === value);
                return (
                  <Chip key={value} label={matchingCategory ? matchingCategory.category : ''} />
                );
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {categories.map(category => (
            <MenuItem
              key={category.id}
              value={category.id} // Use a simple value here
              style={getStyles(category.category, personName, theme)}
            >
              {category.category}
            </MenuItem>
          ))}
        </Select>

      </FormControl>
    </div>
  );
}

export default settingsProfileIntrestsList;
