import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, Toolbar, Typography, styled, useTheme } from '@mui/material';
import ActivityHeader from 'app/components/layout/header/activity-header';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchPetitionDetails, fetchUnauthorizedPetitionDetails } from 'app/shared/reducers/activity-home';
import PetitionDetailInsideSection from './petition-detail-inside-section';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useItemId } from 'app/utils/hooks/useItemId';
import toast from 'react-hot-toast';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import useMetaTagUpdater from 'app/components/metaTagUpdater';
import metaTagUpdateReverse from 'app/components/MetaTagReverse';
const drawerWidth = 315;
const drawerWidthSm = 248;
interface Props {
  window?: () => Window;
}
const PetitionDetail = (props: Props) => {
  const { window } = props;
  const petitionId = useItemId();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const eventType = useAppSelector(state => state.homeActivity.eventType);
  const sortType = useAppSelector(state => state.homeActivity.sortType);
  const [notFound, setNotFound] = useState(false);
  const [petition, setPetition] = useState('');
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const handleButtonClicked = () => { };
  const handleButtonMapClicked = () => { };
  const handleGoBack = () => {
    metaTagUpdateReverse();
    navigate(-1);
  };

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };

  const handleAccordionClick = (categoryId: any, currentState: any) => {
    setCategory(categoryId);
  };
  const handleButtonFeatureClicked = () => { };
  const container = window !== undefined ? () => window().document.body : undefined;

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  useEffect(() => {
    const fetchActivitiesInfo = async () => {
      let response;
      try {
        if (isAuthenticated) {
          response = await dispatch(fetchPetitionDetails(petitionId));
        } else {
          response = await dispatch(fetchUnauthorizedPetitionDetails(petitionId));
        }

        if (response.meta.requestStatus === 'fulfilled') {
          setNotFound(false)
          response.payload.caption = response.payload.details;
          useMetaTagUpdater(response.payload);
          setPetition(response.payload);
        } else if (response.meta.requestStatus === 'rejected') {
          console.error('No petition details found:', response.payload);
          setNotFound(true)
        }
      } catch (error) {
        console.error('Error fetching petition details:', error);
        setNotFound(true)
      }
    };

    fetchActivitiesInfo();
  }, [sortType, eventType, isAuthenticated, petitionId]);

  const drawer = (
    <Box
      sx={{
        border: 'none',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : 'transparent linear-gradient(133deg, #F7F5EF 35%, #F2F2FA 58%, #EFEFF5 100%) 0% 0% no-repeat padding-box',

        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {/* <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" />
        </Link>
      </DrawerHeader> */}
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>
      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        onMapClicked={handleButtonMapClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          background: isDarkMode ? '#2C2C38' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
          p: 0,
          border: 'none',
          backdropFilter: 'blur(10px)',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <CssBaseline />

        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
            background: isDarkMode ? '#2C2C38' : 'transparent',
          }}
        >
          <Toolbar
            className="amy-top-nav"
            sx={{
              backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
              background: isDarkMode ? '#1F1F26' : '',
              border: 'none',
              backdropFilter: 'blur(10px)',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
            >
              <MenuIcon />
            </IconButton>
            <PrimaryHeader />
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
            flexShrink: { sm: 0 },
            minHeight: '100vh',
            border: 'none',
            background: isDarkMode
              ? '#2C2C38'
              : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          className="inside-bg"
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              sm: `calc(100% - ${drawerWidthSm} px)`,
              md: `calc(100% - ${drawerWidthSm} px)`,
              lg: `calc(100% - ${drawerWidth} px)`,
              background: isDarkMode
                ? '#1F1F26'
                : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box;',
              border: 'none',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <div className="row" style={{ padding: 1, marginTop: '50px' }}>
            <ArrowCircleLeftIcon
              onClick={() => handleGoBack()}
              sx={{
                cursor: 'pointer', color: isDarkMode ? '#FFCE00' : '#775fff',
                borderRadius: '50%', fontSize: '50px', marginLeft: '10px'
              }}
            />
          </div>

          {notFound && (
            <Box
              display="flex"
              height="60vh"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                padding: '24px',
              }}
            >
              <Box
                component="img"
                sx={{
                  borderRadius: '8px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  maxHeight: { xs: 300, md: 200 },
                  maxWidth: { xs: 400, md: 300 },
                }}
                alt="No activity found illustration"
                src="content/images/empty.svg"
              />
              <Typography
                fontSize="24px"
                mt={3}
                fontWeight="bold"
                sx={{
                  color: isDarkMode ? '#FFCE00' : '#4A4A4A',
                  textAlign: 'center',
                }}
              >
                Petition Not Found
              </Typography>
              <Typography
                fontSize="16px"
                mt={1}
                color={isDarkMode ? '#BFBFBF' : '#757575'}
                sx={{
                  textAlign: 'center',
                }}
              >
                Sorry, the petition you're looking for doesn't exist or has been removed.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 4,
                  background: isDarkMode ? '#FFCE00' : '#674BFF',
                  color: '#fff',
                  borderRadius: '20px',
                  padding: '10px 24px',
                  textTransform: 'none',
                  fontWeight: '600',
                  '&:hover': {
                    background: isDarkMode ? '#FFC800' : '#563ECC',
                  },
                }}
                onClick={() => navigate('/')}
              >
                Back to Home
              </Button>
            </Box>
          )}
          {!notFound && (<PetitionDetailInsideSection petitionData={petition} />)}

        </Box>
      </Box>
    </>
  );
};

export default PetitionDetail;
