import { Avatar, Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from 'react-router-dom';
import { truncateString } from 'app/utils/common-utils/common-utils';

type MainProps = {
  title: string;
  description: string;
  data: any;
  type: string;
};

type UserProps = {
  id: number;
  name: string;
  count: string;
  rank: number;
  image: string;
  isDarkMode: boolean;
  isOrganization: boolean;
};

export const themeColor = '#8974FC';

const UserCard = ({ id, name, count, rank, image, isDarkMode, isOrganization }: UserProps) => (
  <Box
    height={51}
    display="flex"
    alignItems="center"
    gap={0.26}
    justifyContent="space-between"
    sx={{
      '&:hover': {
        '& .left-card': {
          backgroundColor: '#AB9DFB4F',
        },
        '& .right-card': {
          backgroundColor: '#8974FC',
        },
        '& .count-text': {
          color: '#fff',
        },
      },
    }}
  >
    <Link
      to={isOrganization ? `/organization/${name?.replace(/\s+/g, '-')}-${id}` : `/profile/${name?.replace(/\s+/g, '-')}-${id}`}
      style={{ textDecoration: 'none', flex: 1, display: 'flex', alignItems: 'center', gap: '1rem' }}
      className="left-card"
    >
      <Avatar  src={image && typeof image === 'string' ? image : undefined} sx={{ height: 36, width: 36 }} />
      <Box>
        <Typography
          title={name}
          variant="body2"
          fontWeight="bold"
          color={isDarkMode ? '#FFCE00' : '#674BFF'}
          fontSize="0.74rem"
        >
          {truncateString(name, 18)}
        </Typography>
        <Typography variant="body2" fontWeight="bold" color="#A4A4A4" fontSize="0.73rem">
          {count}
        </Typography>
      </Box>
    </Link>

    <Box
      height="inherit"
      width={50}
      className="right-card"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        transition: 'background-color 0.3s',
        backgroundColor: 'inherit',
      }}
    >
      <Typography
        variant="body2"
        fontWeight="bold"
        color="#646262"
        fontSize="0.77rem"
        className="count-text"
        sx={{
          transition: 'color 0.3s',
        }}
      >
        {rank}
      </Typography>
    </Box>
  </Box>
);


// const UserCard = ({ id, name, count, rank, image, isDarkMode, isOrganization }: UserProps) => (
//   <Box
//     height={51}
//     component={Link}
//     to="#"
//     display="flex"
//     alignItems="center"
//     gap={0.26}
//     justifyContent="space-between"
//     sx={{
//       '&:hover': {
//         '& .left-card': {
//           backgroundColor: '#AB9DFB4F',
//         },
//         '& .right-card': {
//           backgroundColor: '#8974FC',
//         },
//         '& .count-text': {
//           color: '#fff',
//         },
//       },
//     }}
//   >
//     <Box
//       to={isOrganization ? `/organization/${id}` : `/profile/${id}`}
//       component={Link}
//       className="left-card"
//       pl={2.1}
//       height="inherit"
//       display="flex"
//       alignItems="center"
//       gap={2.2}
//       flex="1"
//       sx={{
//         transition: 'background-color 0.3s',
//         backgroundColor: 'inherit',
//       }}
//     >
//       <Avatar src={image} sx={{ height: 36, width: 36 }} />
//       <Box>
//         <Typography title={name} variant="body2" fontWeight="bold" color={isDarkMode ? '#FFCE00' : '#674BFF'} fontSize="0.74rem">
//           {truncateString(name, 18)}
//         </Typography>
//         <Typography variant="body2" fontWeight="bold" color="#A4A4A4" fontSize="0.73rem">
//           {count}
//         </Typography>
//       </Box>
//     </Box>

//     <Box
//       height="inherit"
//       width={50}
//       className="right-card"
//       display="flex"
//       alignItems="center"
//       justifyContent="center"
//       sx={{
//         transition: 'background-color 0.3s',
//         backgroundColor: 'inherit',
//       }}
//     >
//       <Typography
//         variant="body2"
//         fontWeight="bold"
//         color="#646262"
//         fontSize="0.77rem"
//         className="count-text"
//         sx={{
//           transition: 'background-color 0.3s',
//           color: 'inherit',
//         }}
//       >
//         {rank}
//       </Typography>
//     </Box>
//   </Box>
// );

export default function RankingCard({ title, description, data, type }: MainProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      position="relative"
      overflow="hidden"
      bgcolor={isDarkMode ? '#2c2c38' : '#fff'}
      maxHeight="373px"
      borderRadius={1.7}
    >
      <Box p={2.1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography component="h5" display="flex" alignItems="center" fontWeight="bold" fontSize="0.9rem">
            {title}
          </Typography>
          {/* <MoreHorizIcon sx={{ fontSize: '1rem' }} /> */}
        </Box>
        <Typography variant="body2" fontWeight="600" color="#798498" fontSize="0.7rem">
          {description}
        </Typography>
      </Box>
      <Box
        pb={4}
        flex="1"
        overflow="scroll"
        sx={{
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'primary.light',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AAA',
            borderRadius: '16px',
          },
        }}
      >
        {data?.length > 0 ? (
          data
            ?.filter(user => user.rank !== undefined)
            .sort((a, b) => a.rank - b.rank)
            .map(user => (
              <UserCard
                isDarkMode={isDarkMode}
                key={user.orgainzerId}
                id={user.orgainzerId}
                name={user.organizerName}
                image={user.organizerImageUrl}
                isOrganization={user.organization}
                count={
                  type === 'views'
                    ? user.viewCount
                    : type == 'volunteers'
                    ? user.volunteerCount
                    : type == 'upvotes'
                    ? user.upvoteCount
                    : type == 'shares'
                    ? user.shareCount
                    : null
                }
                rank={user.rank}
              />
            ))
        ) : (
          <Typography p={2.1} textAlign="center" variant="body2">
            No data found
          </Typography>
        )}
      </Box>
      <div className={isDarkMode ? 'dark-gradient-fade' : 'gradient-fade'}></div>
      <Box position="absolute" bottom="0" left="0" right="0" height={6} bgcolor="#8974FC"></Box>
    </Box>
  );
}
