import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import CreatePetitionMainSec from '../../ui/create-petition/create-petition-main-sec/create-petition-main-sec';
import CreatePetitionPostingRules from './create-petition-posting-rules/create-petition-posting-rules';
import { useTheme } from '@mui/material/styles';
import { createPetitionSchema } from 'app/utils/validation-schema/petition-schema';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import PostSuccessModal from 'app/components/common/post-success-modal/post-success-modal';
import { findDifferentFields } from 'app/utils/common-utils/common-utils';
import { sendActivityDraft } from 'app/shared/reducers/activity/activity-draft';
import toast from 'react-hot-toast';
import GenericModal from 'app/components/common/modals/generic-modal';
import { useAppDispatch } from 'app/config/store';
import { sendPetitionDraft } from 'app/shared/reducers/petition/petition-draft';
import { clearExistingActivities } from 'app/shared/reducers/activity-filter';
import useMetaTagUpdater from 'app/components/metaTagUpdater';
import metaTagUpdateReverse from 'app/components/MetaTagReverse';

const initialValues = {
  categoryID: '',
  title: '',
  details: '',
  postAsOrganisation: false,
  urgent: false,
  targetSignature: '',
  multimedia: [],
  petitionTo: [],
  startDate: '',
  endDate: '',
  latitude: '',
  longitude: '',
};

const CreatePetition = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [open, setOpen] = useState(false);
  const [responseUrl, setResponseUrl] = useState<string>('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const [confirmGoBack, setConfirmGoBack] = useState(false);
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleCreatePetitionSubmit = async activity => {
    setIsButtonDisabled(true);
    try {
      const response = await axios.post<any>('api/petition/submit', activity);
      if (response.status === 201) {
        dispatch(clearExistingActivities());

        setResponseUrl(response.data.link);
        setOpen(true);
        let payloadDummy = { ...activity };
        payloadDummy.caption = activity.details;
        payloadDummy.eventGallery = activity.multimedia;
        payloadDummy.eventGallery[0].link = activity.multimedia[0].url;
        useMetaTagUpdater(payloadDummy);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: createPetitionSchema,
    onSubmit: (values, { resetForm }) => {
      handleCreatePetitionSubmit(values);
    },
  });
  const handleModalClose = () => {
    metaTagUpdateReverse();
    setOpen(false);
    navigate('/');
  };
  useEffect(() => {
    if (formik?.values && initialValues) {
      const userChanges = findDifferentFields(initialValues, formik?.values);

      setFieldsChanged(userChanges);
    }
  }, [formik?.values, initialValues]);
  React.useEffect(() => {
    const unloadHandler = (e: any) => {
      if (fieldsChanged) {
        e.preventDefault();
        // setRouteBack(true);
        e.returnValue = 'Are you sure you want to leave this page? Your changes may not be saved.';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [fieldsChanged]);

  const modalActionButtons = [
    {
      label: 'Save Draft',
      onClick: async () => {
        setConfirmGoBack(true);
        const response = await dispatch(sendPetitionDraft(formik.values));
        if (sendPetitionDraft.fulfilled.match(response)) {
          toast.success('Draft saved successfully');
          navigate(-1);
        } else if (sendPetitionDraft.rejected.match(response)) {
          toast.error('Failed to save draft');
        }
      },
    },
    { label: 'Discard', onClick: () => navigate(-1) },
  ];
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else {
      navigate('/');
    }
  };
  return (
    <div className=" container py-2 mx-3" style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none' }}>
      <style>
        {`
         ::-webkit-scrollbar {
        width: 0;
        background: transparent; 
      }     
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    `}
      </style>
      <div className="row mt-3">
        <div className="col-3">
          <ArrowCircleLeftIcon
            onClick={handleNavigationArrowButton}
            sx={{ cursor: 'pointer', color: isDarkMode ? '#FFCE00' : '#775fff', borderRadius: '50%', fontSize: '29px' }}
          />
        </div>
        <div className="col-9">
          <Typography variant="h4" sx={{ color: 'secondary.main' }}>
            Post Petition
          </Typography>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-1 g-3">
          <div className="col-md-3 col-sm-12 col-10 ">
            <CreatePetitionPostingRules />
          </div>
          <div className="col-md-9 col-sm-12 col-10">
            <CreatePetitionMainSec formik={formik} isButtonDisabled={isButtonDisabled} onCancel={handleNavigationArrowButton} />
          </div>
        </div>
      </form>

      <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={responseUrl}
        message="The petition has been posted successfully"
      />
      <GenericModal
        handleClose={() => setRouteBack(false)}
        fullScreen={fullScreen}
        open={routeBack}
        title="Do you want to keep your changes?"
        buttons={modalActionButtons}
      />
    </div>
  );
};

export default CreatePetition;
